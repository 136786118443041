





























































import {Component, Prop, Vue} from 'vue-property-decorator';
import PersonSearch from "@/components/common/PersonSearch.vue";
import PersonDTO from "@/dto/person/PersonDTO";
import CreateITRQPayloadDTO from "@/dto/request/itrq/CreateITRQPayloadDTO";
import {namespace} from "vuex-class";
import IndividualTaxationService from "@/services/request/IndividualTaxationService";
import PortalInput from "@/components/common/PortalInput.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import { ifValid, processError } from "@/utils/ComponentUtils";

const AppModule = namespace("App");

@Component({
  components: {
    PersonSearch,
    PortalCheckbox,
    PhoneInput,
    PortalInput,
  }
})
export default class CreateITRQ extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  onCreated!: () => void

  person: PersonDTO | null = null;
  payload = new CreateITRQPayloadDTO();
  registered = true;

  create() {
    ifValid(this, () => {
      if (this.registered && !this.person) {
        return;
      }
      if (this.person) {
        this.payload.personId = this.person.id;
      }
      this.startLoading();
      IndividualTaxationService.createQuestionnaire(this.payload).then(
        ok => {
          this.stopLoading();
          this.onCreated();
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
      )
    });
  }

  resetPayload() {
    this.person = null;
    this.payload = new CreateITRQPayloadDTO();
  }

}
