import { render, staticRenderFns } from "./PublicIAAR.vue?vue&type=template&id=9519c0bc&scoped=true&"
import script from "./PublicIAAR.vue?vue&type=script&lang=ts&"
export * from "./PublicIAAR.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9519c0bc",
  null
  
)

export default component.exports