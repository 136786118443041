import FileMetaDTO from "@/dto/files/FileMetaDTO";

export default class PortalFileDTO {

    public id: number | null = null;

    public fileMeta: FileMetaDTO | null = null;

    public fileRelation: string | null = null;

}