



















import {Vue, Component} from 'vue-property-decorator';
import {IEmployment} from "@/dto/payroll/IEmployment";
import {EmploymentFilter} from "@/dto/payroll/Filters";
import Application from "@/state/Application";
import EmploymentService from "@/services/EmploymentService";
import {processError} from "@/utils/ComponentUtils";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import SelectOption from "@/components/common/SelectOption";
import EmploymentSLR from "@/components/payroll/sickLeave/slr/EmploymentSLR.vue";
import EmploymentSickLeaveList from "@/components/payroll/sickLeave/EmploymentSickLeaveList.vue";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";

@Component({
	computed: {
		SelectOption() {
			return SelectOption
		}
	},
	components: {EmploymentSickLeaveList, EmploymentSLR, PortalRadio}
})
export default class IndividualSickLeave extends Vue {

	private employments: Array<IEmployment> = [];

	private selectedEmployment: IEmployment | null = null;

	private filter = new EmploymentFilter(
		{
			detailsId: this.$wss.getCurrent.id,
			status: [EmploymentStatus.NEW, EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS]
		}
	)

	private loadEmployments() {
		Application.startLoading();
		EmploymentService.getAllByFilter<IEmployment>(this.filter).then(
			res => {
				console.log(res.data)
				if (res.data.length > 0) {
					this.selectedEmployment = res.data[0]
					this.employments = res.data
				}
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	public created(): void {
		this.loadEmployments()
	}

}
