import {WORKSPACE_TYPE} from "@/constants/Paths";
import {WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import {Route} from "vue-router";
import RouteNames from "@/router/RouteNames";
import Workspaces from "@/state/Workspaces";

class ContainmentOptions {
    hasAdminPanel = false;
    additionalConditions: (route: Route) => boolean = (route: Route) => true;

    constructor(init?: Partial<ContainmentOptions>) {
        Object.assign(this, init);
    }
}

function opts(hasAdminPanel = false, additional = (route: Route) => true) {
    return new ContainmentOptions({hasAdminPanel: hasAdminPanel, additionalConditions: additional});
}

const workspaceTypePerson = (r: Route) => r.params[WORKSPACE_TYPE] == WorkspaceTypePathElement.PERSON
const workspaceTypeCompany = (r: Route) => r.params[WORKSPACE_TYPE] == WorkspaceTypePathElement.COMPANY
const workspaceTypeEmployee = (r: Route) => r.params[WORKSPACE_TYPE] == WorkspaceTypePathElement.COMPANY

const routesWithSublimeEmployeeId: Partial<Record<keyof typeof RouteNames, ContainmentOptions>> = {
    [RouteNames.USER_PROFILE]: new ContainmentOptions(),
    [RouteNames.PROFILE_ACCOUNT]: new ContainmentOptions(),
    [RouteNames.AUDIT]: new ContainmentOptions()
}

const routesWithPersonId: Partial<Record<keyof typeof RouteNames, ContainmentOptions>> = {
    [RouteNames.PAYROLL_INDIVIDUAL]: new ContainmentOptions(),
    [RouteNames.PAYROLL_INDIVIDUAL_PAYSTUBS]: new ContainmentOptions(),
    [RouteNames.PAYROLL_INDIVIDUAL_FORMS]: new ContainmentOptions(),
    [RouteNames.PAYROLL_INDIVIDUAL_FORMS_W2]: new ContainmentOptions(),
    [RouteNames.PAYROLL_INDIVIDUAL_FORMS_1099]: new ContainmentOptions(),
    [RouteNames.PAYROLL_INDIVIDUAL_INCOME_TAX_FEDERAL]: new ContainmentOptions(),
    [RouteNames.PAYROLL_INDIVIDUAL_INCOME_TAX_STATE]: new ContainmentOptions(),
    [RouteNames.PAYROLL_INDIVIDUAL_POLICY]: new ContainmentOptions(),
    [RouteNames.PAYROLL_INDIVIDUAL_POLICY_PTO]: new ContainmentOptions(),
    [RouteNames.PAYROLL_INDIVIDUAL_POLICY_SICK_LEAVE]: new ContainmentOptions(),
    [RouteNames.PAYROLL_INDIVIDUAL_VACATION_REQUEST]: new ContainmentOptions(),
    [RouteNames.PAYROLL_INDIVIDUAL_SICK_LEAVES]: new ContainmentOptions(),
    [RouteNames.PAYROLL_INDIVIDUAL_VACATIONS]: new ContainmentOptions(),
    [RouteNames.PAYROLL_INDIVIDUAL_VACATIONS_LIST]: new ContainmentOptions(),
    [RouteNames.PAYROLL_INDIVIDUAL_VACATIONS_REQUESTS]: new ContainmentOptions(),
    [RouteNames.PAYROLL_INDIVIDUAL_VACATIONS_BALANCE_DETAILS]: new ContainmentOptions(),
    [RouteNames.PROFILE_PERSON_DOCUMENTS]: new ContainmentOptions()
}

const routesWithCompanyId: Partial<Record<keyof typeof RouteNames, ContainmentOptions>> = {
    [RouteNames.PAYROLL_BUSINESS]: new ContainmentOptions(),
    [RouteNames.PAYROLL_COMPANY_PROJECT]: new ContainmentOptions(),
    [RouteNames.PAYROLL_BUSINESS_EMPLOYEES]: new ContainmentOptions(),
    [RouteNames.PAYROLL_BUSINESS_PAYSTUBS]: new ContainmentOptions(),
    [RouteNames.PAYROLL_BUSINESS_FORMS]: new ContainmentOptions(),
    [RouteNames.PAYROLL_BUSINESS_FORMS_W2]: new ContainmentOptions(),
    [RouteNames.PAYROLL_BUSINESS_FORMS_1099]: new ContainmentOptions(),
    [RouteNames.PAYROLL_BUSINESS_FORMS_1042S]: new ContainmentOptions(),
    [RouteNames.PAYROLL_BUSINESS_FORMS_K1]: new ContainmentOptions(),
    [RouteNames.PAYROLL_BUSINESS_DETAILS]: new ContainmentOptions(),
    [RouteNames.PAYROLL_BUSINESS_REPORTS]: new ContainmentOptions(),
    [RouteNames.PAYROLL_BUSINESS_REPORTS_FEDERAL]: new ContainmentOptions(),
    [RouteNames.PAYROLL_BUSINESS_REPORTS_REGIONAL]: new ContainmentOptions(),
    [RouteNames.SALES_TAX]: new ContainmentOptions({hasAdminPanel: true}),
    [RouteNames.SALES_TAX_DOCUMENTS]: new ContainmentOptions(),
    [RouteNames.SALES_TAX_REPORTS]: new ContainmentOptions(),
    [RouteNames.SALES_TAX_PERMITS]: new ContainmentOptions(),
    [RouteNames.SALES_TAX_SUBSCRIPTIONS]: new ContainmentOptions(),
    [RouteNames.SALES_TAX_SUBSCRIPTION]: new ContainmentOptions(),
    [RouteNames.SALES_TAX_WORKFLOWS]: new ContainmentOptions(),
    [RouteNames.SALES_TAX_WORKFLOW]: new ContainmentOptions(),
    [RouteNames.PAYROLL_COMPANY_SICK_LEAVE_LIST]: new ContainmentOptions(),
    [RouteNames.PAYROLL_BUSINESS_VACATIONS]: new ContainmentOptions(),
    [RouteNames.PAYROLL_BUSINESS_VACATIONS_LIST]: new ContainmentOptions(),
    [RouteNames.PAYROLL_BUSINESS_VACATIONS_REQUESTS]: new ContainmentOptions(),
    [RouteNames.PAYROLL_BUSINESS_VACATIONS_BALANCE_DETAILS]: new ContainmentOptions(),
    [RouteNames.PAYROLL_COMPANY_POLICY]: new ContainmentOptions(),
    [RouteNames.PAYROLL_COMPANY_POLICY_PTO]: new ContainmentOptions(),
    [RouteNames.PAYROLL_COMPANY_POLICY_SICK_LEAVE]: new ContainmentOptions(),
    [RouteNames.PAYROLL_REPORTS_BUILDER]: new ContainmentOptions(),
    [RouteNames.PROFILE_COMPANY_DOCUMENTS]: new ContainmentOptions()
}

const routesWithWorkspaceTypeAndId: Partial<Record<keyof typeof RouteNames, ContainmentOptions>> = {
    [RouteNames.TIMESHEET]: new ContainmentOptions(),
    [RouteNames.USER_PROFILE]: new ContainmentOptions(),
    [RouteNames.PROFILE_ACCOUNT]: new ContainmentOptions(),
    [RouteNames.PROFILE_ADDRESS]: new ContainmentOptions(),
    [RouteNames.PROFILE_BRANCH_LIST]: new ContainmentOptions(),
    [RouteNames.PROFILE_MARITAL_STATUS]: new ContainmentOptions(),
    [RouteNames.PROFILE_ID_CARD]: new ContainmentOptions(),
    [RouteNames.PROFILE_SHAREHOLDER]: new ContainmentOptions(),
    [RouteNames.PROFILE_PERSON_DETAILS]: new ContainmentOptions(),
    [RouteNames.PROFILE_COMPANY_DETAILS]: new ContainmentOptions(),
    [RouteNames.PROFILE_COMPANY_BANK_STATEMENTS]: new ContainmentOptions(),
    [RouteNames.PROFILE_COMPANY_BUSINESS_TAX_RETURN]: new ContainmentOptions(),
    [RouteNames.PROFILE_BANK_ACCOUNTS]: new ContainmentOptions(),
    [RouteNames.PROFILE_BANK_ACCOUNT]: new ContainmentOptions(),
    [RouteNames.PROFILE_OTHER_BANK_PRODUCTS]: new ContainmentOptions(),
    [RouteNames.ARCHIVE]: new ContainmentOptions(),
    [RouteNames.ARCHIVE_AUTH]: new ContainmentOptions(),
    [RouteNames.ARCHIVE_W8BEN]: new ContainmentOptions(),
    [RouteNames.ARCHIVE_W9]: new ContainmentOptions(),
    [RouteNames.ARCHIVE_W4]: new ContainmentOptions(),
    [RouteNames.ARCHIVE_F8822]: new ContainmentOptions(),
    [RouteNames.ARCHIVE_IT2104]: new ContainmentOptions(),
    [RouteNames.ARCHIVE_INSURANCE]: new ContainmentOptions(),
    [RouteNames.ARCHIVE_INSURANCE_GENERAL_LIABILITY]: new ContainmentOptions(),
    [RouteNames.ARCHIVE_INSURANCE_PROFESSIONAL_LIABILITY]: new ContainmentOptions(),
    [RouteNames.ARCHIVE_INSURANCE_WORKERS_COMPENSATION]: new ContainmentOptions(),
    [RouteNames.ARCHIVE_INSURANCE_DISABILITY]: new ContainmentOptions(),
}

const publicPages = [
    RouteNames.LOGIN,
    RouteNames.PRICES,
    RouteNames.HOME,
    RouteNames.EMAIL_CONFIRMATION,
    RouteNames.INVITATION,
    RouteNames.RESET_PASSWORD,
    RouteNames.LOCATIONS,
    RouteNames.TAXATION_INDIVIDUAL_QUESTIONNAIRE_PUBLIC,
    RouteNames.PUBLIC_FORMS_W8BEN,
    RouteNames.PUBLIC_FORMS_W9_COMPANY,
    RouteNames.PUBLIC_FORMS_W9_PERSON,
    RouteNames.PUBLIC_FORMS_W4,
    RouteNames.PUBLIC_FORMS_IT2104,
    RouteNames.SALES_TAX_PUBLIC_WORKFLOW,
    RouteNames.RCCR_CUSTOMER,
    RouteNames.CCDR_CUSTOMER,
    RouteNames.CBSR_CUSTOMER,
    RouteNames.W9CR_CUSTOMER,
    RouteNames.W9CR_COMPANY,
    RouteNames.W9CR_PERSON,
    RouteNames.FRSO_CUSTOMER,
    RouteNames.ITRQ_CUSTOMER,
    RouteNames.CDRQ_CUSTOMER,
    RouteNames.TAXATION_INDIVIDUAL_ESTIMATED_OFFER,
    RouteNames.RIDC_PUBLIC,
    RouteNames.RIDC_PUBLIC_CONTRACTOR,
    RouteNames.RER_CUSTOMER,
    RouteNames.SIGN_PUBLIC,
    RouteNames.RER_EMPLOYEE_PUBLIC,
    RouteNames.IAAR_PUBLIC,
    RouteNames.RER_EMPLOYEE_PUBLIC,
    RouteNames.ARCHIVE_PREPARATION_1099_NEC_PUBLIC
];

export function shouldContainSublimeEmployeeId(route: Route): boolean {
    if (route.name as string in routesWithSublimeEmployeeId) {
        const opts = routesWithSublimeEmployeeId[route.name as keyof typeof RouteNames];
        return !!opts && opts.additionalConditions(route);
    }
    return false;
}

export function shouldContainPersonId(route: Route): boolean {
    if (route.name as string in routesWithPersonId) {
        const opts = routesWithPersonId[route.name as keyof typeof RouteNames];
        return !!opts && opts.additionalConditions(route) && (!opts.hasAdminPanel || Workspaces.isUserSublimeEmployee);
    }
    return false;
}

export function shouldContainCompanyId(route: Route): boolean {
    if (route.name as string in routesWithCompanyId) {
        const opts = routesWithCompanyId[route.name as keyof typeof RouteNames];
        return !!opts && opts.additionalConditions(route) && (!opts.hasAdminPanel || Workspaces.isUserSublimeEmployee);
    }
    return false;
}

export function shouldContainWorkspaceTypeAndId(route: Route): boolean {
    if (route.name as string in routesWithWorkspaceTypeAndId) {
        const opts = routesWithWorkspaceTypeAndId[route.name as keyof typeof RouteNames];
        return !!opts && opts.additionalConditions(route) && (!opts.hasAdminPanel || Workspaces.isUserSublimeEmployee);
    }
    return false;
}

export function isPublicPage(route: string){
    return publicPages.includes(route);
}
