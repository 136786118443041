var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticStyle:{"font-size":"30px"}},[_vm._v("Foreign contractors")]),_c('div',{staticClass:"mb-3"},[_c('portal-radio',{attrs:{"title":"Employment type","id":"employment_type","options":_vm.statusOptions},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}}),_c('portal-input',{attrs:{"label":"Name","name":"name","placeholder":"Enter the contractor's name"},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}}),_c('portal-select',{attrs:{"label":"Contractor type","name":"type","options":_vm.buildOptions()},model:{value:(_vm.filter.contractorType),callback:function ($$v) {_vm.$set(_vm.filter, "contractorType", $$v)},expression:"filter.contractorType"}}),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('button',{staticClass:"portal-button",on:{"click":_vm.reset}},[_vm._v("Reset")]),_c('button',{staticClass:"portal-button",on:{"click":_vm.search}},[_vm._v("Search")])])],1),(_vm.contractors.length !== 0)?_c('div',{staticClass:"admin_table_border"},[_vm._m(0),_vm._l((_vm.contractors),function(contractor,index){return _c('router-link',{key:index,staticClass:"d-flex align-items-center admin_table admin_list_item admin_list_item_border table-row-clickable",staticStyle:{"display":"block","width":"100%"},attrs:{"to":{
						 name: contractor.counterpartyType === _vm.CounterpartyType.COMPANY ?
						 	_vm.RouteNames.PAYROLL_COMPANY_BUSINESS_FOREIGN_CONTRACTOR
						 	:
						 	_vm.RouteNames.PAYROLL_COMPANY_INDIVIDUAL_FOREIGN_CONTRACTOR,
						 params: {
							 companyId: _vm.$wss.getCurrent.id,
							 contractorId: contractor.id
						 }
					 }}},[_c('div',{staticClass:"col-md-3 p-2",staticStyle:{"text-align":"center"}},[_c('span',[_vm._v(_vm._s(contractor.details.name))])]),_c('div',{staticClass:"col-md-3 p-2",staticStyle:{"text-align":"center"}},[_c('span',[_vm._v(_vm._s(contractor.counterpartyType))])]),_c('div',{staticClass:"col-md-3 p-2",staticStyle:{"text-align":"center"}},[_c('span',[_vm._v(_vm._s(_vm.$dateUtils.formatDateToAmericanFormat(contractor.employmentStart)))])]),_c('div',{staticClass:"col-md-3 p-2",staticStyle:{"text-align":"center"}},[_c('span',[_vm._v(_vm._s(contractor.status))])])])}),_c('pagination-component',{attrs:{"page-number":_vm.filter.pageNumber + 1,"total-pages":_vm.totalPages,"on-change-page":function (page) { return this$1.changePage(page); }}})],2):_c('div',{staticClass:"m-4 d-flex justify-content-center"},[_c('h3',{staticStyle:{"color":"#436356"}},[_vm._v("No contractors")])]),_c('div',{staticClass:"d-flex align-items-center justify-content-end mb-3"},[_c('button',{staticClass:"portal-button",on:{"click":function($event){$event.preventDefault();return _vm.addContractor.apply(null, arguments)}}},[_vm._v(" Register a contractor ")])]),(_vm.message)?_c('div',{staticClass:"alert auth-form-element",class:_vm.successful ? 'alert-success' : 'alert-danger'},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center admin_title"},[_c('div',{staticClass:"col-md-3 p-2 text-center"},[_vm._v(" Name ")]),_c('div',{staticClass:"col-md-3 p-2 text-center"},[_vm._v(" Type ")]),_c('div',{staticClass:"col-md-3 p-2 text-center"},[_vm._v(" Registration date ")]),_c('div',{staticClass:"col-md-3 p-2 text-center"},[_vm._v(" Status ")])])}]

export { render, staticRenderFns }