





























































import { Component, Vue } from "vue-property-decorator";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import { ifValid, processError } from "@/utils/ComponentUtils";
import IAARService from "@/services/request/IAARService";
import Application from "@/state/Application";
import PublicPersonValidPayload from "@/dto/request/iaar/PublicPersonValidPayload";
import PublicPersonNotValidPayload from "@/dto/request/iaar/PublicPersonNotValidPayload";
import IAARPublicPersonDTO from "@/dto/request/iaar/IAARPersonDTO";
import { STATE_LIST } from "@/constants/ProfileConstants";
import SelectOption from "@/components/common/SelectOption";
import { IAARPersonStatus } from "@/constants/request/IAARConstants";

@Component<PublicIAAR>({
  components: { PortalInput, PortalSelect },
  computed: {
    SelectOption() {
      return SelectOption;
    },
    states(): Array<string> {
      return STATE_LIST;
    },
    IAARPersonStatus() {
      return IAARPersonStatus;
    }
  }
})

export default class PublicIAAR extends Vue {

  private validPayload = new PublicPersonValidPayload();
  private notValidPayload = new PublicPersonNotValidPayload();
  private publicPerson = new IAARPublicPersonDTO();
  private showFormAddress = false;

  mounted(): void {
    this.loadPublicPerson();
  }

  loadPublicPerson(): void {
    Application.startLoading();
    IAARService.getPublicPerson(this.publicId).then(
      ok => {
        this.publicPerson = ok.data;
        Application.stopLoading();
      },
      err => {
        Application.stopLoading();
        processError(err, this);
      }
    )
  }

  handleValidAddress(): void {
    Application.startLoading();
    IAARService.savePublicPersonValid(this.publicId, this.validPayload).then(
      ok => {
        this.loadPublicPerson();
        Application.stopLoading();
      },
      err => {
        Application.stopLoading();
        processError(err, this);
      }
    )
  }

  confirm(): void {
    ifValid(this,() => {
      Application.startLoading();
      IAARService.savePublicPersonNotValid(this.publicId, this.notValidPayload).then(
        ok => {
          this.loadPublicPerson();
          Application.stopLoading();
        },
        err => {
          Application.stopLoading();
          processError(err, this);
        }
      )
    })
  }

  get publicId(): string {
    return this.$route.params.publicId;
  }

}
