
import {Vue, Component} from 'vue-property-decorator';
import CDRQ from "@/components/request/cdrq/CDRQ.vue";
import Application from "@/state/Application";
import CDRQService from "@/services/request/CDRQService";
import {processError} from "@/utils/ComponentUtils";
import RouteNames from "@/router/RouteNames";

@Component({})
export default class EmployeeCDRQ extends CDRQ {


	protected loadCDRQ() {
		Application.startLoading()
		CDRQService.getByInternalId(this.$route.params.id).then(
			cdrq => {
				this.cdrq = cdrq.data;
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

}
