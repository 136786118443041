

































import {Component, Prop, Vue} from "vue-property-decorator";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import FileService from "@/services/FileService";
import {namespace} from "vuex-class";
import TaxationUserQuestionnaireDTO, {TaxationFormDTO} from "@/dto/TaxationUserQuestionnaireDTO";
import TaxationService from "@/services/TaxationService";
import SaveFormValueRequestDTO from "@/dto/SaveFormValueRequestDTO";
import PersonalTaxationFileDTO from "@/dto/files/PersonalTaxationFileDTO";
import {BUSINESS_QUESTIONNAIRE, PERSONAL_QUESTIONNAIRE} from "@/constants/FileRelationsConstants";
import {documentAccept} from "@/constants/FileConstants";
import FileUpload from "@/components/common/FileUpload.vue";
import ListItem from "@/components/common/ListItem.vue";
import UserPersonalDataDTO from "@/dto/UserPersonalDataDTO";

const Taxation = namespace("Taxation");
const Auth = namespace("Auth");
const Profile = namespace("Profile");

@Component({
    components: {
        FileUpload,
        ListItem
    }
})
export default class FilesListModal extends Vue{

    private accept = documentAccept;

    @Prop()
    private form!: TaxationFormDTO

    @Prop()
    private recalcPrice!: () => void

    @Auth.Getter("isAdmin")
    private isAdmin!: boolean;

    @Auth.Getter("isModerator")
    private isModerator!: boolean;

    @Taxation.State
    private questionnaire!: TaxationUserQuestionnaireDTO

    @Profile.State
    private personalData!: UserPersonalDataDTO;

    save(){
        TaxationService.saveFormValue(new SaveFormValueRequestDTO(
            this.form.valueId,
            this.form.id,
            this.questionnaire.id,
            this.form.files)).then(
                response => {
                    this.form.valueId = response.data.valueId;
                    this.recalcPrice();
                },
            error => {
                const message =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                return Promise.reject(message);
            }
        );
    }


    remove(file: PersonalTaxationFileDTO) {
        this.form.files.forEach((element,index)=>{
            if(element == file) this.form.files.splice(index,1);
        });
        this.save();
    }

    onFilesUploaded(files: FileMetaDTO[]){
        files.forEach(f => {
            const fileDto = new PersonalTaxationFileDTO();
            fileDto.fileMeta = f;
            fileDto.fileRelation = PERSONAL_QUESTIONNAIRE;
            fileDto.questionnaireFormValueId = this.form.valueId;
            this.form.files.push(fileDto);
        });
        this.save();
    }

    closeModal(){
        this.$modal.hideAll();
    }

    generateName(){
        return this.personalData.firstName
            + '_' + this.personalData.lastName
            + '_' + this.form.defaultName
            + '_' + this.questionnaire.period
            + '_' + new Date().getTime();
    }

}

