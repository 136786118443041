import { IEmployment } from "@/dto/payroll/IEmployment";
import {IProfile} from "@/dto/IProfile";

export class VacationFilter {
    startDate: Date | string | null = null;
    endDate: Date | string | null = null;
    employmentsId: Array<number> = [];
    employerId: number | null = null;
    page = 0;
    size = 20;

    constructor(init?: Partial<VacationFilter>) {
        Object.assign(this, init);
    }
}

export class AllVacationFilter {
    startDate: Date | string | null = null;
    endDate: Date | string | null = null;
    employmentsId: Array<number> = [];
    employerId: number | null = null;

    constructor(init?: Partial<VacationFilter>) {
        Object.assign(this, init);
    }
}

export class VCRFilter {
    employerId: number | null = null;
    employmentsId: Array<number> | null = null;
    status: VCRStatus | null = null;
    page = 0;
    size = 20;
    startDate: Date | null = null;
    endDate: Date | null = null;

    constructor(init?: Partial<VCRFilter>) {
        Object.assign(this, init);
    }
}

export class AllVCRFilter {
    employerId: number | null = null;
    employmentsId: Array<number> | null = null;
    status: VCRStatus | null = null;
    startDate: Date | string | null = null;
    endDate: Date | string | null = null;

    constructor(init?: Partial<VCRFilter>) {
        Object.assign(this, init);
    }
}

export class VacationDto {
    id!: number;
    startDate!: Date;
    endDate!: Date;
    startTime!: Date;
    endTime!: Date;
    duration!: string;
    paid!: boolean;
    employment!: IEmployment;
    startDateTime!: Date;
    endDateTime!: Date;
}

export class CreateVacationPayload {
    startDate: Date | null = null;
    endDate: Date | null = null;
    startTime: Date | string | null = null;
    endTime: Date | string | null = null;
    employmentId: number | null = null;
    isPaid = false;

    constructor(init?: Partial<CreateVacationPayload>) {
        Object.assign(this, init);
    }
}

export class CreateVCRequestPayload extends CreateVacationPayload {
    employeeComment: string | null = null;

    constructor(init?: Partial<CreateVCRequestPayload>) {
        super(init);
    }
}

export class VacationBalance {
    id!: number;
    balance!: number;
    totalBalanceHours!: string;
    totalBalanceDays!: string;
    duration!: string;
}

export class UpdateVacationCarryoverPayload {
    employmentId: number | null = null;
    balance: number | null = null;

    constructor(init?: Partial<UpdateVacationCarryoverPayload>) {
        Object.assign(this, init);
    }
}

export enum VCRStatus {
    REQUESTED = "REQUESTED",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    CANCELLED = "CANCELLED",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
}

export class VCR {
    id!: number;
    status!: VCRStatus;
    requester!: IEmployment;
    employeeComment: string | null = null;
    assigneeComment: string | null = null;
    vacation!: VacationDto;
    assignee!: IProfile;
    createDate!: Date;
}

export class DecisionVCR extends VCR {
    probationaryPeriod?: boolean;
    vacationBalance?: VacationBalance;
    enoughBalance?: boolean
}

export class ChangeStatusVCRRequestPayload {
    requestId: number | null = null;
    status: VCRStatus | null = null;
    employmentId: number | null = null;

    constructor(init?: Partial<ChangeStatusVCRRequestPayload>) {
        Object.assign(this, init);
    }
}

export class RejectRequestPayload {
    requestId: number | null = null;
    companyId: number | null = null;
    comment: string | null = null;

    constructor(init?: Partial<RejectRequestPayload>) {
        Object.assign(this, init);
    }
}

export class ApproveRequestPayload {
    requestId: number | null = null;
    companyId: number | null = null;
    comment: string | null = null;

    constructor(init?: Partial<ApproveRequestPayload>) {
        Object.assign(this, init);
    }
}

export class BalanceDetails {
    id!: number;
    employment!: IEmployment;
    positive!: boolean;
    duration!: string;
    createDate!: Date;
}

export class VacationBalanceDetailsDTO extends BalanceDetails {}

export class VacationBalanceDetails extends BalanceDetails {}

export class VacationBalanceDetailForPeriod {
    data!: Array<VacationBalanceDetailsDTO>;
    totalPages!: number;
    balance!: number;
    balanceForPeriod!: number;
}

export class BalanceDetailsFilterForPeriod {
    employmentId: number | null = null;
    startDate: Date | null = null;
    endDate: Date | null = null;
    page = 0;
    size = 20;

    constructor(init?: Partial<BalanceDetailsFilterForPeriod>) {
        Object.assign(this, init)
    }
}
