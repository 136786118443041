

























import {Component, Prop, Vue} from "vue-property-decorator";
import {CDRQFileMeta, CDRQStep} from "@/components/request/cdrq/CDQR_DTOs";

@Component({})
export default class CDRQSummarySingleFileRow extends Vue {

	@Prop()
	file!: CDRQFileMeta;

	@Prop({default: null, required: false})
	private step!: CDRQStep | null

}
