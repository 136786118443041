import {InternalSaveAction} from "@/constants/Form1099NECPrearationConstants";

export default class EditForm1099NECPreparationPayload {
    action?: InternalSaveAction;
    corrected?: boolean;
    amount?: string;
    resale?: boolean;
    federalWithheld?: string;
    stateWithheld1?: string;
    stateWithheld2?: string;
    payerStateNo1?: string;
    payerStateNo2?: string;
    stateIncome1?: string;
    stateIncome2?: string;
    accountNumber?: string;
    repreparationReason?: string;

    constructor(init?: Partial<EditForm1099NECPreparationPayload>) {
        Object.assign(this, init);
    }
}