import {BankAccountType} from "@/dto/BankAccountType";
import {HolderType} from "@/dto/HolderType";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {IProfile} from "@/dto/IProfile";
import Workspaces from "@/state/Workspaces";
import {WorkspaceType} from "@/dto/auth/Workspace";

export default class BankAccountDTO {
    id: number | null = null;

    accountNumber: string | null = null;

    bankName: string | null = null;

    routingNumber: string | null = null;

    city: string | null = null;

    state: string | null = null;

    achForm: AchForm | null = null;

    holder!: IProfile

    companyId: number | null = null

    personId: number | null = null

    isMain: boolean | null = null;

    accountHolder: string | null = null;

    verified: boolean | null = null;

    accountType: BankAccountType | null = BankAccountType.CHECKING

    holderType: HolderType | null = null;

    detailsDocument: FileMetaDTO | null = null;

    manualForm: FileMetaDTO | null = null;

    active = true;
}

type AchFormType = "MANUAL" | "SIGNABLE"

export class AchForm {
    formType!: AchFormType;
    signingStatus: string | null = null;
    form!: FileMetaDTO
}