











import { Component, Prop, Vue } from 'vue-property-decorator';
import Progress from './Progress.vue';

@Component({
    components: {
        Progress
    }
})
export default class ListItem extends Vue {

    @Prop({ default: '' })
    private filename!: string;

    @Prop({ default: 'idle' })
    private uploadState!: string;

    @Prop({ default: 0 })
    private percentage!: number;
}
