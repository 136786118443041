

















import {Vue, Component} from 'vue-property-decorator';
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import CDRQDocument from "@/components/request/cdrq/CDRQDocument.vue";
import {CDRQ_Director, CDRQ_Owner} from "@/components/request/cdrq/CDQR_DTOs";
import {ifValid} from "@/utils/ComponentUtils";

@Component({
	components: {CDRQDocument, PhoneInput, PortalInput, PortalCheckbox}
})
export default class CDRQDirector extends Vue {
	private director: CDRQ_Director = new CDRQ_Director();

	save() {
		ifValid(this, () => {
			this.$emit("save", this.director)
			this.director = new CDRQ_Director()
		})
	}
}
