








































































































































































































































import {Component, Vue} from "vue-property-decorator";
import AuditDTO from "@/components/admin/audit/AuditDTO";
import moment from "moment";
import {namespace} from "vuex-class";
import AuditService from "@/components/admin/audit/AuditService";
import {IProfile} from "@/dto/IProfile";
import {prettyEnum} from "@/utils/StringUtils";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import AuditFilter from "@/components/admin/audit/AuditFilter";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import PersonSearch from "@/components/common/PersonSearch.vue";
import PersonDTO from "@/dto/person/PersonDTO";
import CompanySearch from "@/components/common/CompanySearch.vue";
import SublimeEmployee from "@/components/admin/employee/SublimeEmployee.vue";
import SublimeSearch from "@/components/common/SublimeSearch.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalDateTime from "@/components/common/PortalDateTime.vue";
const AppModule = namespace("App");

export type AccountType = "PERSON" | "COMPANY" | "SUBLIME_EMPLOYEE"

@Component({
	components: {PortalDateTime, PortalDate, PortalInput, SublimeSearch, SublimeEmployee, CompanySearch, PersonSearch, PortalSelect, PaginationComponent, PortalCollapse},
	methods: {prettyEnum},
	computed: {
		PersonDTO() {
			return PersonDTO
		},
		PaginationComponent() {
			return PaginationComponent
		},
		moment() {
			return moment
		}
	}
})
export default class Audit extends Vue{

	private auditEvents: Array<AuditDTO> = [];

	private filterIsOpen = false;

	private keyReset = 0;

	private message = "";

	private successful = true;

	private totalPages = 0

	private dateTimeFrom: string | null = null;

	private dateTimeTo: string | null = null;

	private producerType: AccountType | null = null;

	private consumerType: AccountType | null = null;

	private events: Array<string> = [];

	private filter = new AuditFilter()

	@AppModule.State
	private isMobile!: boolean;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	public created(): void {
		this.loadEvents();
		this.loadEventIds();
	}

	private extractNameIfPossible(account?: IProfile) {
		return account?.name || "Anonymous"
	}

	private loadEvents(): void {
		this.startLoading();
		this.filter.dateTimeFrom = this.dateTimeFrom != null ? moment(this.dateTimeFrom).utc().toISOString() : null
		this.filter.dateTimeTo = this.dateTimeTo != null ? moment(this.dateTimeTo).utc().toISOString() : null;
		AuditService.getAllAuditEvents(this.filter).then(
			res => {
				this.auditEvents = res.data.data
				for (let auditEvent of this.auditEvents) {
					auditEvent.props = new Map(Object.entries(auditEvent.props))
				}
				this.totalPages = res.data.countOfPages
				this.stopLoading()
			},
			err => {
				this.stopLoading();
			}
		)
	}

	private resetFilter() {
		this.filter = new AuditFilter();
		this.loadEvents();
		this.dateTimeFrom = null;
		this.dateTimeTo = null;
		this.producerType = null;
		this.consumerType = null;
		this.keyReset = this.keyReset++
	}

	private search() {
		this.loadEvents()
	}

	loadEventIds(): void {
		this.startLoading();
		AuditService.getAllEvents().then(
			res => {
				this.events = res.data
				this.stopLoading()
			},
			err => {
				this.stopLoading();
			}
		)
	}

	changePage(page: number){
		this.filter.page = page - 1;
		this.loadEvents();
	}

	private buildAccountOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title("Person").value("PERSON").build(),
			SelectOption.builder().title("COMPANY").value("COMPANY").build(),
			SelectOption.builder().title("SUBLIME_EMPLOYEE").value("SUBLIME_EMPLOYEE").build(),
		]
	}

	private buildEvents(): Array<SelectOption> {
		return this.events.map(event => SelectOption.builder().title(prettyEnum(event)).value(event).build())
	}
}

