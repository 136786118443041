


























































































































































































































































































































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import PersonSearch from "@/components/common/PersonSearch.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import EmbeddedFile from "@/components/common/EmbeddedFile.vue";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import {PersonSex, ResidencyStatus, SSNType, StateIDSubtype} from "@/constants/PersonConstants";
import {PublicREREmployeeDTO} from "@/dto/request/rer/PublicREREmployeeDTO";
import PersonDTO from "@/dto/person/PersonDTO";
import {namespace} from "vuex-class";
import EmployeeService from "@/services/EmployeeService";
import {EmployeeFilter} from "@/dto/payroll/Filters";
import PersonService from "@/services/PersonService";
import PortalAddress from "@/components/profile/branches/PortalAddress.vue";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import CompleteREREmployeePayload, {
  CitizenPassportDataDTO,
  EADDataDTO,
  GreenCardDataDTO, SSNCardDataDTO, StateIDDataDTO
} from "@/dto/request/rer/CompleteREREmployeePayload";
import RERService from "@/services/request/RERService";
import {REREmployeeStatus} from "@/constants/request/RERConstants";
import {processError} from "@/utils/ComponentUtils";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";

const AppModule = namespace("App");

@Component({
  computed: {
    AutoCompleteDTO() {
      return AutoCompleteDTO
    },
    SSNType() {
      return SSNType
    },
    StateIDSubtype() {
      return StateIDSubtype
    },
    PersonSex() {
      return PersonSex
    },
    ResidencyStatus() {
      return ResidencyStatus
    },
    REREmployeeStatus() {
      return REREmployeeStatus
    },
  },
  components: {
    AutoComplete,
    PortalCheckbox,
    PortalAddress,
    PortalCollapse,
    PortalInput,
    SingleFileHolder,
    PortalDate,
    PersonSearch,
    EmbeddedFile,
  }
})
export default class EmployeeRER extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  employee = new PublicREREmployeeDTO();

  payload = new CompleteREREmployeePayload();

  createPerson = false;

  selectedPerson: PersonDTO | null = null;

  foundEmployees: Array<EmployeeDto> = [];

  selectedEmployee: EmployeeDto | null = null;

  hasNoHireDate = false;

  mounted(): void {
    this.initPayload();
  }

  initPayload(): void {
    this.startLoading();
    RERService.getFullInformationEmployee(this.id).then(
        ok => {
          this.employee = ok.data;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
    ).then(() => {
      this.payload.id = this.employee.id;
      this.payload.residencyStatus = this.employee.residencyStatus;
      this.payload.employmentStart = this.employee.employmentStart;
      switch (this.employee.residencyStatus!) {
        case ResidencyStatus.CITIZEN:
          if (this.employee.citizenPassportRequired) {
            this.payload.passport = new CitizenPassportDataDTO();
            this.payload.passport.file = this.employee.citizenPassportImage;
          }
          break;
        case ResidencyStatus.PERMANENT_RESIDENT:
          if (this.employee.greenCardRequired) {
            this.payload.greenCard = new GreenCardDataDTO();
            this.payload.greenCard.front = this.employee.greenCardFrontImage;
            this.payload.greenCard.back = this.employee.greenCardBackImage;
          }
          break;
        case ResidencyStatus.NON_CITIZEN:
          if (this.employee.eadRequired) {
            this.payload.ead = new EADDataDTO();
            this.payload.ead.front = this.employee.eadFrontImage;
            this.payload.ead.back = this.employee.eadBackImage;
          }
          break;
      }
      if (this.employee.ssnRequired) {
        this.payload.ssn = new SSNCardDataDTO();
        this.payload.ssn.file = this.employee.ssnImage;
      }
      if(this.employee.stateIdRequired && !this.employee.stateIdIsValid){
        this.payload.stateID = new StateIDDataDTO();
        this.payload.stateID.front = this.employee.stateIdFrontImage;
        this.payload.stateID.back = this.employee.stateIdBackImage;
      }
      this.hasNoHireDate = !this.payload.employmentStart;
    });
  }

  loadPerson(): void {
    if (this.employee.personId) {
      this.startLoading();
      PersonService.getById(this.employee.id).then(
          ok => {
            this.selectedPerson = ok.data;
            this.stopLoading();
          },
          err => {
            processError(err, this);
            this.stopLoading();
          }
      )
    }
  }

  onPersonSelected(person: PersonDTO): void {
    this.startLoading();
    this.payload.personId = person.id;
    this.payload.firstName = person.firstName;
    this.payload.lastName = person.lastName;
    if (this.employee.ssnRequired && this.payload.ssn) {
      this.payload.ssn.ssn = person.ssn;
    }
    const filter = new EmployeeFilter();
	filter.status = [EmploymentStatus.NEW, EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS]
    filter.detailsId = person.id;
    EmployeeService.findByFilter(filter).then(
        ok => {
          this.foundEmployees = ok.data.data.filter(e => e.employer?.id == this.employee.companyId);
          if (this.foundEmployees.length == 1) {
            this.selectedEmployee = this.foundEmployees[0];
            this.payload.employeeId = this.selectedEmployee.id;
          }
          this.stopLoading();
        },
        err => {
          processError(err, this);
          this.stopLoading();
        }
    )
  }

  save(): void {
    this.$validator.validateAll().then(isValid => {
      if (isValid) {
        this.startLoading();
        RERService.completeEmployeeRegistration(this.payload).then(
            ok => {
              this.stopLoading();
              location.reload();
            },
            err => {
              processError(err, this);
              this.stopLoading();
            }
        )
      }
    })
  }

  employeeChanged(): void {
    if (this.selectedEmployee) {
      this.payload.employeeId = this.selectedEmployee.id;
    } else {
      this.payload.employeeId = null;
    }
  }

  sendForSigning(): void {
    this.startLoading();
    RERService.sendI9ForSigning(this.employee.id).then(
        ok => {
          this.employee.status = REREmployeeStatus.I9_BEING_SIGNED;
          this.stopLoading();
        },
        err => {
          processError(err, this);
          this.stopLoading();
        }
    )
  }

  get id(): number {
    return Number.parseInt(this.$route.params.id);
  }

  get statusTitle(): string {
    switch (this.employee!.status) {
      case REREmployeeStatus.NEW:
        return "Sent"
      case REREmployeeStatus.FILLED_BY_CUSTOMER:
        return "Sent"
      case REREmployeeStatus.OPENED_BY_EMPLOYEE:
        return "Seen"
      case REREmployeeStatus.DOCUMENTS_PARTIALLY_UPLOADED:
        return "Documents have been partially uploaded"
      case REREmployeeStatus.ALL_DOCUMENTS_UPLOADED:
        return "All documents have been uploaded"
      case REREmployeeStatus.I9_PREPARED:
        return "I-9 is prepared"
      case REREmployeeStatus.I9_BEING_SIGNED:
        return "I-9 is being signed by employee"
      case REREmployeeStatus.I9_SIGNED:
        return "I-9 is signed by employee"
      case REREmployeeStatus.COMPLETED:
        return "Completed"
      default:
        return this.employee.status as string;
    }
  }

  get residencyStatusTitle(): string {
    switch (this.employee!.residencyStatus) {
      case ResidencyStatus.CITIZEN:
        return "A citizen of the United States"
      case ResidencyStatus.PERMANENT_RESIDENT:
        return "A lawful permanent resident"
      case ResidencyStatus.NON_CITIZEN:
        return "A noncitizen"
      default:
        return `${this.employee.residencyStatus}`;
    }
  }

  get isFederalUploaded(): boolean {
    switch (this.employee?.residencyStatus) {
      case ResidencyStatus.CITIZEN: return !this.employee.citizenPassportRequired || !!this.employee.citizenPassportImage;
      case ResidencyStatus.PERMANENT_RESIDENT: return !this.employee.greenCardRequired || !!this.employee.greenCardFrontImage && !!this.employee.greenCardBackImage;
      case ResidencyStatus.NON_CITIZEN: return !this.employee.eadRequired || !!this.employee.eadFrontImage && !!this.employee.eadBackImage;
      default: return false;
    }
  }

  get isStateIdUploaded(): boolean {
    return !this.employee.stateIdRequired
        || ((!!this.employee.latestStateIdState && this.employee.stateIdIsValid) || !!this.employee.stateIdFrontImage);
  }

  get isSSNUploaded(): boolean {
    return !this.employee.ssnRequired || !!this.employee.ssnImage;
  }

  get isAllButVoidCheckUploaded(): boolean {
    return this.isFederalUploaded && this.isStateIdUploaded && this.isSSNUploaded;
  }

  continueWithoutBankDetails() {
    this.startLoading();
    RERService.skipUploadingStage(this.id).then(
        ok => {
          this.startLoading();
          location.reload();
        },
        err => {
          processError(err, this);
          this.stopLoading();}
    )
  }
}
