















import {Vue, Component, Prop} from 'vue-property-decorator';
import CDRQFileHolder from "@/components/request/cdrq/CDRQFileHolder.vue";
import {CDRQ_Director} from "@/components/request/cdrq/CDQR_DTOs";

@Component({
	components: {CDRQFileHolder}
})
export default class CDRQDirectorView extends Vue {

	@Prop()
	private director!: CDRQ_Director

}
