export enum RelationshipToTaxpayer {
  SON = "SON",
  DAUGHTER = "DAUGHTER",
  STEPCHILD = "STEPCHILD",
  FOSTER_CHILD = "FOSTER_CHILD",
  GRANDCHILD = "GRANDCHILD",
  GRANDPARENT = "GRANDPARENT",
  PARENT_OR_IN_LAW = "PARENT_OR_IN_LAW",
  BROTHER = "BROTHER",
  HALF_BROTHER = "HALF_BROTHER",
  STEPBROTHER = "STEPBROTHER",
  SISTER = "SISTER",
  HALF_SISTER = "HALF_SISTER",
  STEPSISTER = "STEPSISTER",
  UNCLE = "UNCLE",
  AUNT = "AUNT",
  NEPHEW = "NEPHEW",
  NIECE = "NIECE",
  OTHER = "OTHER"
}
