




















import {Component, Vue} from 'vue-property-decorator';
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import W9CRContractorDTO from "@/dto/request/w9cr/W9CRContractorDTO";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import AutoComplete from "@/components/common/AutoComplete.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import PhoneInput from "@/components/util/PhoneInput.vue";
import {InputType} from "@/components/common/InputType";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {FormFiller, W9CRContractorStatus} from "@/constants/request/W9CRConstants";
import W9CRContractorItem from "@/components/request/w9cr/W9CRContractorItem.vue";
import W9CR_DTO from "@/dto/request/w9cr/W9CR_DTO";
import W9CRService from "@/services/request/W9CRService";
import {namespace} from "vuex-class";

const AppModule = namespace("App");

@Component({
  computed: {
    FileMetaDTO() {
      return FileMetaDTO
    },
    W9CRContractorStatus() {
      return W9CRContractorStatus
    },
    InputType() {
      return InputType
    },
    AutoCompleteDTO() {
      return AutoCompleteDTO
    },
    FormFiller() {
      return FormFiller
    }
  },
  components: {W9CRContractorItem, PhoneInput, PortalInput, AutoComplete, SingleFileHolder, PortalCollapse}
})
export default class CustomerW9CR extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  request: W9CR_DTO | null = null;

  mounted(){
    this.loadRequest();
  }

  loadRequest(){
    this.startLoading();
    return W9CRService.getPublic(this.id).then(
        ok => {
          this.request = ok.data;
          this.request.contractors = this.request?.contractors.map(c => new W9CRContractorDTO(c)).sort((a,b) => a.id - b.id)
          this.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading();
        }
    )
  }

  get id(){
    return this.$route.params.id;
  }

  onW9Uploaded(b: W9CRContractorDTO){
    b.status = W9CRContractorStatus.COMPLETED
  }

  onFileUploaded(file: FileMetaDTO){
    console.log("uploaded")
  }

  filled(b: W9CRContractorDTO){
    b.status = W9CRContractorStatus.COMPLETED;
  }

  sendToContractor(b: W9CRContractorDTO){
    b.status = W9CRContractorStatus.SENT_TO_CONTRACTOR;
  }

  cancel(b: W9CRContractorDTO){
    b.status = W9CRContractorStatus.CANCELLED_BY_CUSTOMER;
  }

  getUploadLambda(b: W9CRContractorDTO): (file: FileMetaDTO) => void {
    return (file: FileMetaDTO) => {this.filled(b)};
  }

}
