

















import {Vue, Component, Watch} from 'vue-property-decorator';
import {CompanyDocumentsDTO, UploadCompanyDocumentsPayload} from "@/dto/company/CompanyDetailsDTO";
import Application from "@/state/Application";
import CompanyService from "@/services/CompanyService";
import {processError} from "@/utils/ComponentUtils";
import CompanyDocument from "@/components/profile/company/documents/CompanyDocument.vue";
import Notifications from "@/state/Notifications";

@Component({
	components: {CompanyDocument}
})
export default class CompanyDocuments extends Vue {

	private docs: CompanyDocumentsDTO | null = null

	public mounted(): void {
		this.loadDocuments();
	}

	private loadDocuments() {
		Application.startLoading()
		CompanyService.getDocuments().then(
			res => {
				this.docs = res.data
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private uploadDocuments(): void {
		Application.startLoading()
		CompanyService.uploadDocuments(
			new UploadCompanyDocumentsPayload(
				{
					ss4: this.docs!.ss4,
					certificateOfIncorporation: this.docs!.certificateOfIncorporation,
					fillingReceipt: this.docs!.fillingReceipt,
					companyId: this.$wss.getCurrent.id
				}
			)
		).then(
			() => {
				Notifications.success("The document has been updated successfully!");
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

}

