
















import {Component, VModel, Vue} from 'vue-property-decorator';
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import SelectOption from "@/components/common/SelectOption";
import {CDRQRecordStatuses} from "@/components/request/cdrq/CDQR_DTOs";


@Component({
	computed: {
		CDRQRecordStatuses() {
			return CDRQRecordStatuses
		}
	},
	components: {PortalRadio}
})
export default class CDRQRecordStatus extends Vue {

	@VModel({type: String, required: true})
	private status!: CDRQRecordStatuses;

	private STATUS_OPTIONS = [
		SelectOption.builder().title("Document Not Available").value(CDRQRecordStatuses.NONE).build(),
		SelectOption.builder().title("Will Submit Later").value(CDRQRecordStatuses.LATER).build()
	]

	private ok() {
		this.$emit("input", CDRQRecordStatuses.OK)
		this.$emit("upload")
	}
}
