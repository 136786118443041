














import {Component, VModel, Vue} from 'vue-property-decorator';
import {CDRQFileMeta, CDRQRecordStatuses} from "@/components/request/cdrq/CDQR_DTOs";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import CDRQRecordStatus from "@/components/request/cdrq/CDRQRecordStatus.vue";
import Notifications from "@/state/Notifications";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import CDRQFileHolder from "@/components/request/cdrq/CDRQFileHolder.vue";

@Component({
	computed: {
		CDRQRecordStatuses() {
			return CDRQRecordStatuses
		}
	},
	components: {CDRQFileHolder, CDRQRecordStatus, SingleFileHolder}
})
export default class CDRQDocument extends Vue {

	@VModel({type: CDRQFileMeta, required: true})
	private cdrqFileMeta!: CDRQFileMeta

	private changeDoc(doc: FileMetaDTO | null) {
		this.cdrqFileMeta.doc = doc
		this.$emit("input", this.cdrqFileMeta)
	}

	public created(): void {
		this.$validator.extend(
			"requiredOkCdrqFile",
			{
				validate: (value) => {
					if (!value.doc && value.status == CDRQRecordStatuses.OK) {
						Notifications.error(value.title + " file was not uploaded!")
						console.log(false)
						return false
					} else {
						return true
					}
				}
			}
		)
	}

	private onDelete() {
		this.cdrqFileMeta.doc = null
		this.$emit("input", this.cdrqFileMeta)
	}

}
