


























import {Vue, Component} from 'vue-property-decorator';
import PublicForm1099NECPreparationDTO from "@/dto/preparation/PublicForm1099NECPreparationDTO";
import PreparationService from "@/services/PreparationService";
import {namespace} from "vuex-class";
import {processError} from "@/utils/ComponentUtils";
import EmbeddedFile from "@/components/common/EmbeddedFile.vue";
import {Form1099NECPreparationStatus, PublicSaveAction} from "@/constants/Form1099NECPrearationConstants";
import SavePublicForm1099NECPreparationPayload from "@/dto/preparation/SavePublicForm1099NECPreparationPayload";
import TextFieldModal from "@/components/common/TextFieldModal.vue";

const AppModule = namespace("App");

@Component({
  computed: {
    Form1099NECPreparationStatus() {
      return Form1099NECPreparationStatus
    }
  },
  components: {EmbeddedFile}
})
export default class Public1099NEC extends Vue {

  @AppModule.Action startLoading!: () => void;
  @AppModule.Action stopLoading!: () => void;

  preparation: PublicForm1099NECPreparationDTO | null = null;

  mounted(): void {
    this.loadPreparation();
  }

  loadPreparation(): void {
    this.startLoading();
    PreparationService.getPublicForm1099NECPreparation(this.id).then(
        ok => {
          this.preparation = ok.data;
          this.stopLoading();
        },
        err => {
          processError(err, this);
          this.stopLoading()
        }
    )
  }

  get id(): string {
    return this.$route.params.publicId;
  }

  save(action: PublicSaveAction, rejectionReason?: string): void {
    this.startLoading();
    PreparationService.savePublic1099NECPreparation(this.id, new SavePublicForm1099NECPreparationPayload({action: action, rejectionReason: rejectionReason})).then(
        ok => {
          this.stopLoading();
          this.loadPreparation();
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
    )
  }

  reject(): void {
    this.$modal.show(
        TextFieldModal,
        {
          onOk: (rejectionReason: string) => this.save(PublicSaveAction.REJECT, rejectionReason),
          title: "Please, specify the reason",
          required: true,
        }
    )
  }

  approve(): void {
    this.save(PublicSaveAction.APPROVE);
  }

}
