import {RequestMetaDTO} from "@/dto/request/RequestMetaDTO";
import {ITRQStatus} from "@/constants/request/RequestsStatuses";
import {ITRQStep} from "@/constants/request/ITRQStep";
import {MaritalStatus} from "@/dto/taxation/individual/MaritalStatus";
import {ITRQDocumentType} from "@/constants/request/ITRQDocumentType";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import { RelationshipToTaxpayer } from "@/constants/request/ITRQConstants";
import { HolderType } from "@/dto/HolderType";
import { BankAccountType } from "@/dto/BankAccountType";

export default class ITRQ_DTO {
    id!: number;
    request!: RequestMetaDTO;
    status!: ITRQStatus;
    step!: ITRQStep;
    year!: number;
    language!: string;
    firstName!: string | null;
    lastName!: string | null;
    birthDate!: Date | null;
    citizenship!: string | null;
    ssn!: string | null;
    noSsn = false;
    maritalStatus!: MaritalStatus | null;
    spouseFirstName!: string | null;
    spouseLastName!: string | null;
    spouseBirthDate!: Date | null;
    spouseSsn!: string | null;
    spouseNoSsn = false;
    spouseOccupation!: string | null;
    spouseEmail!: string | null;
    dependents!: Array<ITRQDependent>;
    residenceAddresses!: Array<ITRQResidenceAddress>;
    unemployed!: boolean | null;
    occupation!: string | null;
    income!: ITRQIncomeDTO;
    onlineMerchantIncome!: ITRQOnlineMerchantIncomeDTO;
    deductions!: ITRQDeductionsDTO;
    insurance!: ITRQMedicalInsuranceDTO;
    foreignBankAccounts!: ITRQForeignBankAccountsDTO;
    hadCryptocurrency!: boolean | null;
    hadTradeAccount!: boolean | null;
    haveIrsLetters!: boolean | null;
    bankAccount!: ITRQBankAccountDTO;
    estimatedTaxPayments!: boolean | null;
    documents!: Array<ITRQDocumentDTO>;
    signableConfirmationPublicId?: string;
    confirmation?: FileMetaDTO;

    constructor(init ?: Partial<ITRQ_DTO>){
        Object.assign(this, init);
    }
}

export class ITRQIncomeDTO {
    fw2!: boolean;
    fw2g!: boolean;
    f1099misc!: boolean;
    f1099nec!: boolean;
    f1099g!: boolean;
    f1099int!: boolean;
    f1099div!: boolean;
    f1099ssa!: boolean;
    f1099r!: boolean;
    f1099b!: boolean;
    f1099k!: boolean;
    alimonyReceived!: boolean;
    fk1!: boolean;
    juryDutyIncome!: boolean;
    rentalIncome!: boolean;
    other!: boolean;
    otherName!: string | null;

    constructor(init ?: Partial<ITRQIncomeDTO>){
        Object.assign(this, init);
    }
}

export class ITRQOnlineMerchantIncomeDTO {
    zelle!: boolean;
    zelleService!: boolean | null;
    airbnb!: boolean;
    amazon!: boolean;
    etsy!: boolean;
    lyft!: boolean;
    paypal!: boolean;
    square!: boolean;
    uber!: boolean;
    venmo!: boolean;
    vrbo!: boolean;
    otherOnlineStore!: boolean;
    otherOnlineStoreName!: string | null;
    otherPointOfSale!: boolean;
    otherPointOfSaleName!: string | null;
    other!: boolean;
    otherName!: string | null;
    noIncome!: boolean;

    constructor(init ?: Partial<ITRQOnlineMerchantIncomeDTO>){
        Object.assign(this, init);
    }
}

export class ITRQDeductionsDTO {
    f1098!: boolean;
    f5498ira!: boolean;
    health!: boolean;
    f1098t!: boolean;
    f1098e!: boolean;
    teacherExpenses!: boolean;

    constructor(init ?: Partial<ITRQDeductionsDTO>){
        Object.assign(this, init);
    }
}

export class ITRQMedicalInsuranceDTO {
    haveInsurance!: boolean | null;
    free!: boolean | null;
    haveForms!: boolean | null;

    constructor(init ?: Partial<ITRQMedicalInsuranceDTO>){
        Object.assign(this, init);
    }
}

export class ITRQForeignBankAccountsDTO {
    hadForeignBankAccount!: boolean | null;
    amountExceeded10k!: boolean | null;
    country!: string | null;

    constructor(init ?: Partial<ITRQForeignBankAccountsDTO>){
        Object.assign(this, init);
    }
}

export class ITRQBankAccountDTO {
    accountNumber!: string | null;
    routingNumber!: string | null;
    accountType!: BankAccountType | null;
    holderType!: HolderType | null;

    constructor(init?: Partial<ITRQBankAccountDTO>) {
        Object.assign(this, init);
    }
}

export class ITRQDocumentDTO {
    id!: number;
    type!: ITRQDocumentType;
    file!: FileMetaDTO;

    constructor(init?: Partial<ITRQDocumentDTO>) {
        Object.assign(this, init);
    }

}

export class ITRQDependent {
    relationshipToTaxpayer!: RelationshipToTaxpayer;
    certificateOfBirth!: FileMetaDTO | null;
    stateIdFront!: FileMetaDTO | null;
    stateIdBack!: FileMetaDTO | null;
    ssn!: FileMetaDTO;
    eadFront!: FileMetaDTO | null;
    eadBack!: FileMetaDTO | null;
    doctorOrSchoolCertificate!: FileMetaDTO;
    livedWithEntireYear = true;
    livedMonths: number | null = null;
}

export class ITRQResidenceAddress {
    order!: number;
    address1!: string;
    address2!: string | null;
    city!: string;
    state!: string;
    zipCode!: string;
    from!: Date | null;
    to!: Date | null;
    residenceOnPeriodStart!: boolean;
    current!: boolean;

    constructor(init?: Partial<ITRQResidenceAddress>) {
        Object.assign(this, init);
    }
}
