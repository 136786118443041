













































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class StepPasswordCheck extends Vue {

  @Prop({default: ''})
  private password!: string

  get rulePassword1(): boolean {
    return /^.{8,128}$/.test(this.password);
  }

  get rulePassword2(): boolean {
    return /^(?=.*[A-ZА-ЯЁ]).+$/.test(this.password);
  }

  get rulePassword3(): boolean {
    return /^(?=.*[a-zа-яё]).+$/.test(this.password);
  }

  get rulePassword4(): boolean {
    return /^(?=.*[0-9]).+$/.test(this.password);
  }

  get rulePassword5(): boolean {
    return /^(?=.*[!@#$%^&+=\-*?\][{}()<>/\\_.,;|'"]).+$/.test(this.password);
  }

}
