





























import {Component, Prop, Vue} from "vue-property-decorator";
import {CDRQFileMetaList, CDRQStep} from "@/components/request/cdrq/CDQR_DTOs";

@Component({})
export default class CDRQSummaryListFileRow extends Vue {

  @Prop()
  list!: CDRQFileMetaList;

	@Prop({default: null, required: false})
	private step!: CDRQStep | null
}
