













import {Vue, Component} from 'vue-property-decorator';
import {AccountingSoftware} from "@/components/request/cdrq/CDQR_DTOs";
import PortalInput from "@/components/common/PortalInput.vue";
import {ifValid} from "@/utils/ComponentUtils";

@Component({
	components: {PortalInput}
})
export default class SoftwareAccount extends Vue {

	private acc = new AccountingSoftware()

	save() {
		ifValid(this, () => {
			this.$emit("save", this.acc)
			this.acc = new AccountingSoftware()
		})
	}
}
