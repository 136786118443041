




















import {Vue, Component} from 'vue-property-decorator';
import axios from "axios";
import {MAIL_ENDPOINT, MAIL_REGISTRY_ENDPOINT, REQUESTS_W9CR_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import Application from "@/state/Application";
import IAARService from "@/services/request/IAARService";
import Notifications from "@/state/Notifications";
import {processError} from "@/utils/ComponentUtils";
import IndividualTaxationService from "@/services/request/IndividualTaxationService";

@Component({})
export default class MyPage extends Vue {

  processName = ""

  auxiliaryMailingParams: Record<string, string> = {}
  newParamName = "";
  newParamValue = "";

  addAuxiliaryProcessParam(){
    if(this.newParamName && this.newParamValue){
      this.auxiliaryMailingParams[this.newParamName] = this.newParamValue;
      this.newParamName = "";
      this.newParamValue = "";
    }
  }

  triggerMailingAuxiliaryProcess(){
    axios.put(`${MAIL_ENDPOINT}/auxiliary/process/${this.processName}`, this.auxiliaryMailingParams, {headers: authHeader()}).then(
        ok => {
          Notifications.info("Ok");
        },
        err => {
          processError(err, this);
        }
    );
  }

  createIAAR() {
    Application.startLoading();
    IAARService.create().then(
      ok => {
        Application.stopLoading();
      },
      err => {
        Application.stopLoading();
        processError(err, this);
      }
    );
  }

  createITRQBatch() {
    Application.startLoading();
    IndividualTaxationService.batch().then(
        ok => {
          console.log("created")
          Application.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
          Application.stopLoading();
        }
    )
  }

}
