






















import { Component, Vue } from "vue-property-decorator";
import Application from "@/state/Application";
import IAARService from "@/services/request/IAARService";
import { processError } from "@/utils/ComponentUtils";
import IAAR_DTO from "@/dto/request/iaar/IAAR_DTO";
import RouteNames from "@/router/RouteNames";

@Component
export default class IAAR extends Vue {

  private request: IAAR_DTO | null = null;

  mounted(): void {
    this.loadIndividuals();
  }

  loadIndividuals(): void {
    Application.startLoading();
    IAARService.get(this.requestId).then(
      ok => {
        this.request = ok.data;
        Application.stopLoading();
      },
      err => {
        Application.stopLoading();
        processError(err, this);
      }
    )
  }

  gotoPerson(id: number): void {
    this.$router.push({name: RouteNames.IAAR_PERSON, params: { requestId: this.requestId, iaarPersonId: id.toString() }});
  }

  get requestId(): string {
    return this.$route.params.requestId;
  }

}
