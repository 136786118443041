import FileMetaDTO from "@/dto/files/FileMetaDTO";
import PersonalTaxationFileDTO from "@/dto/files/PersonalTaxationFileDTO";

export default class SaveFormValueRequestDTO {

    public valueId: number | null

    public formId!: number

    public questionnaireId!: number

    public files: Array<PersonalTaxationFileDTO>

    constructor(valueId: number | null, formId: number, questionnaireId: number, files: Array<PersonalTaxationFileDTO>) {
        this.valueId = valueId;
        this.formId = formId;
        this.questionnaireId = questionnaireId;
        this.files = files;
    }

}