








import {Vue, Component, Prop} from 'vue-property-decorator';
import FileMetaDTO from "@/dto/files/FileMetaDTO";

@Component({})
export default class CDRQFileHolder extends Vue {

	@Prop()
	private doc!: FileMetaDTO

	@Prop({default: true})
	private deletable!: boolean

}
