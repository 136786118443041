




















import {Component, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import {debounce} from 'typescript-debounce-decorator';
import {EmploymentFilter} from "@/dto/payroll/Filters";
import EmploymentService from "@/services/EmploymentService";
import {IEmployment} from "@/dto/payroll/IEmployment";
import {processError} from "@/utils/ComponentUtils";
import {CounterpartyType} from "@/constants/CounterpartyType";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";

@Component({})
export default class EmploymentSearch extends Vue {

  @Model('change', {default: null})
  selected?: IEmployment;

  @Prop({default: "employments"})
  name!: string

  @Prop()
  type!: "EMPLOYEE" | "INDIVIDUAL_DOMESTIC_CONTRACTOR" | "INDIVIDUAL_FOREIGN_CONTRACTOR" | "BUSINESS_DOMESTIC_CONTRACTOR" | "BUSINESS_FOREIGN_CONTRACTOR" | "DOMESTIC_CONTRACTOR" | "FOREIGN_CONTRACTOR";

  @Prop()
  principalId!: number;

  @Prop({default: "Contractor name"})
  title!: string;

  filter = new EmploymentFilter(
	  {
		  pageSize: 8,
		  status: [EmploymentStatus.NEW, EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS]
	  }
  );

  employments: Array<IEmployment> = [];

  showList = false;

  mounted(){
    if(this.selected){
      this.filter.name = this.selected.details.name;
    }
    this.filter.employerId = this.principalId;
    this.initContractorType();
  }

  initContractorType(){
    switch (this.type) {
      case "EMPLOYEE":
        this.filter.types.push("STAFF");
        break;
      case "INDIVIDUAL_DOMESTIC_CONTRACTOR":
        this.filter.types.push("CONTRACTOR");
        this.filter.contractorType = CounterpartyType.PERSON;
        break;
      case "BUSINESS_DOMESTIC_CONTRACTOR":
        this.filter.types.push("CONTRACTOR");
        this.filter.contractorType = CounterpartyType.COMPANY;
        break;
      case "INDIVIDUAL_FOREIGN_CONTRACTOR":
        this.filter.types.push("FOREIGN");
        this.filter.contractorType = CounterpartyType.PERSON;
        break;
      case "BUSINESS_FOREIGN_CONTRACTOR":
        this.filter.types.push("FOREIGN");
        this.filter.contractorType = CounterpartyType.COMPANY;
        break;
      case "DOMESTIC_CONTRACTOR":
        this.filter.types.push("CONTRACTOR");
        break;
      case "FOREIGN_CONTRACTOR":
        this.filter.types.push("FOREIGN");
        break;
    }
  }

  @debounce(200)
  search(){
    this.initContractorType();
    EmploymentService.getPageByFilter<IEmployment>(this.filter).then(
        ok => {
          this.employments = ok.data.data.filter(e => [EmploymentStatus.NEW, EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS].includes(e.status as EmploymentStatus));
          this.showList = true;
        },
        err => {
          processError(err, this);
        }
    )
  }

  selectEmployment(e: IEmployment){
    this.showList = false;
    this.filter.name = e.details.name;
    this.$emit('change', e);
  }


  @Watch("selected")
  watchCompany(_new: IEmployment | null, old: IEmployment | null){
    if(_new == null){
      this.filter.name = null
    }
  }

}
