





















































import {Component, Vue} from "vue-property-decorator";
import Workspace, {WorkspaceType} from "@/dto/auth/Workspace";
import {namespace} from "vuex-class";
import CompanyService from "@/services/CompanyService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import ShareholderDTO from "@/dto/company/ShareholderDTO";
import RouteNames from "@/router/RouteNames";
import UserDTO from "@/dto/UserDTO";
import PersonSearchModal from "@/components/common/PersonSearchModal.vue";
import PersonDTO from "@/dto/person/PersonDTO";
import CreateShareholderRequest from "@/dto/company/CreateShareholderRequest";
import Workspaces from "@/state/Workspaces";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import UploadK1Modal from "@/components/payroll/business/UploadK1Modal.vue";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";
import Application from "@/state/Application";
import Notifications from "@/state/Notifications";
import {processError} from "@/utils/ComponentUtils";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
	components: {SingleFileHolder, PortalInput, BTPortalCollapse}
})
export default class Shareholders extends Vue {
  private successful = false;
  private message = "";

  @AppModule.State
  private loading!: boolean;

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  private shareholders: Array<ShareholderDTO> = [];

  @Auth.Getter("hasAdministrativePrivileges")
  private hasAdministrativePrivileges!: boolean;

  @Auth.State
  private user!: UserDTO


  mounted() {
    this.restoreWorkspace();
  }

  restoreWorkspace() {
    this.loadShareholders();
  }

  loadShareholders() {
    this.startLoading();
    this.message = "";
    CompanyService.getAllShareHolderByCompanyId(Workspaces.getCurrent.id).then(
        response => {
          this.shareholders = response.data;
          this.successful = true;
          this.stopLoading()
        },
        error => this.errorHandle(error)
    )
  }

  showModal() {
    this.$modal.show(
        PersonSearchModal,
        {
			title: "Choose person",
          onPersonSelected: (person: PersonDTO) => this.registerAsShareholder(person)
        }
    );
  }

  registerAsShareholder(person: PersonDTO) {
    const request = new CreateShareholderRequest();
    request.companyId = Workspaces.getCurrent.id as number;
    request.personId = person.id;
    this.message = "";
    this.startLoading();
    CompanyService.saveShareholders(request).then(
        success => {
          this.successful = true;
          this.stopLoading();
          this.loadShareholders()
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

  errorHandle(error: any) {
    this.successful = false;
    this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
    this.stopLoading();
  }

	addNewForm(shareholderId: number) {
		this.$modal.show(
			UploadK1Modal,
			{
				onFilesUploaded: () => this.loadShareholders(),
				shareholderId: shareholderId
			});
	}

	deleteShareholder(shareholderId: number) {
	  this.$modal.show(
		  DeleteConfirmation,
		  {
			  targetName: "shareholder",
			  onDelete: () => {
				  Application.startLoading()
				  CompanyService.deleteShareholder(shareholderId).then(
					  () => {
						  Application.stopLoading()
						  Notifications.success("The shareholder has been deleted.");
						  this.loadShareholders()
					  },
					  err => processError(err, this)
				  )
			  }
		  }
	  )
	}



}
