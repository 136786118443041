export default class FeedbackDTO {

    public name = "";

    public phone: string | null = null;

    public email = "";

    public message = "";

    public preferredLanguage = "EN";

    private phoneCode = "+1";

    public promoCode: string | null = null;

}