






























































import {Component, Vue} from 'vue-property-decorator';
import MailRegistryRecordDTO from "@/dto/mail/MailRegistryRecordDTO";
import {namespace} from "vuex-class";
import MailService from "@/services/MailService";
import mailService from "@/services/MailService";
import {MailTemplate} from "@/constants/MailConstants";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import {debounce} from "typescript-debounce-decorator";

const AppModule = namespace("App");

@Component({
  computed: {
    MailTemplate() {
      return MailTemplate
    }
  },
  components: {PortalInput, PaginationComponent}
})
export default class MailRegistry extends Vue {

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  records: Array<MailRegistryRecordDTO> = [];
  page = 1;
  pageSize = 40;
  totalPages = 1;

  templateFilter: MailTemplate | null = null;
  recipientFilter: string | null = null;
  messageIdFilter: string | null = null;

  mounted() {
    this.loadRegistry();
  }

  loadRegistry() {
    this.startLoading();
    return MailService.getRegistry(this.templateFilter, this.recipientFilter, this.messageIdFilter, this.page, this.pageSize).then(
        ok => {
          this.records = ok.data.content;
          this.page = ok.data.number + 1;
          this.totalPages = ok.data.totalPages;
          this.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading();
        }
    )
  }

  resendMessage(messageId: string) {
    this.startLoading();
    return mailService.resendMessage(messageId).then(
        ok => {
          this.stopLoading();
          this.loadRegistry();
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading();
        }
    )
  }

  @debounce(500)
  debouncedSearch(){
    this.loadRegistry();
  }

  clearFilter(){
    this.templateFilter = null;
    this.recipientFilter = null;
    this.messageIdFilter = null;
    this.loadRegistry();
  }

  changePage(page: number){
    this.page = page;
    this.loadRegistry();
  }


}
