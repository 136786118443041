import FileMetaDTO from "@/dto/files/FileMetaDTO";
import AddressDTO from "@/dto/AddressDTO";
import {FormFiller, W9CRContractorStatus} from "@/constants/request/W9CRConstants";
import {ResidencyStatus} from "@/constants/PersonConstants";
import PersonDTO from "@/dto/person/PersonDTO";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import {IContractor} from "@/components/payroll/business/employments/contractors/Contractor";
import BankAccountDTO from "@/dto/BankAccountDTO";

export default class W9CRContractorDTO {
    public id!: number;
    public initialName!: string;
    public status = W9CRContractorStatus.NEW;
    public type: 'PERSON' | 'COMPANY' | null = null;
    public requestingCompany!: string;
    public requestingCompanyId!: number;
    public formFiller: FormFiller | null = null;
    public customerHasForm: boolean | null = null;
    public w9Form: FileMetaDTO | null = null;
    public phone = "";
    public email = "";
    public address = new AddressDTO();

    public w9RequestRequired = true;
    public voidCheck: FileMetaDTO | null = null;
    public bankAccount: BankAccountDTO | null = null;

    public entityName = "";
    public entityEin = "";
    public entityOfficer = "";
    public articleOfIncorporation: FileMetaDTO | null = null;
    public ss4: FileMetaDTO | null = null;
    public addressVerification: FileMetaDTO | null = null;
    public signatoryAuthorization: FileMetaDTO | null = null;
    public companyId: number | null = null;
    public businessContractorId: number | null = null;

    public individualFirstName = "";
    public individualLastName = "";
    public individualSsn = "";
    public individualItin = "";
    public idCard: FileMetaDTO | null = null;
    public ssnImage: FileMetaDTO | null = null;
    public proofOfAddress: FileMetaDTO | null = null;
    public personId: number | null = null;
    public individualContractorId: number | null = null;

    // TODO: refactor
    // auxiliary

    constructor(init?: Partial<W9CRContractorDTO>) {
        Object.assign(this, init);
    }

    public get isCompleted(): boolean {
        return this.status == W9CRContractorStatus.COMPLETED;
    }

    public get isCancelled(): boolean {
        return this.status == W9CRContractorStatus.CANCELLED_BY_CUSTOMER;
    }

    public get isPending(): boolean {
        return this.status == W9CRContractorStatus.SENT_TO_CONTRACTOR
            || this.status == W9CRContractorStatus.PENDING_FOR_APPROVAL
            || this.status == W9CRContractorStatus.DOCUMENTS_PROVIDED;
    }

    public get isReadyForEmployee(){
        return this.status == W9CRContractorStatus.PENDING_FOR_APPROVAL
            || this.status == W9CRContractorStatus.DOCUMENTS_PROVIDED
            || this.status == W9CRContractorStatus.COMPLETED;
    }

    public get contractorTitle(){
        switch (this.type){
            case "COMPANY": return this.entityName?.length >= 3 ? `${this.entityName} (${this.initialName})` : this.initialName;
            case "PERSON": return this.individualFirstName?.length >= 2 && this.individualLastName?.length >= 2
                ? `${this.individualFirstName} ${this.individualLastName} (${this.initialName})` : this.initialName;
            default: return this.initialName;
        }
    }
}