


















import {Component, Prop, Vue} from 'vue-property-decorator';
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import CDRQDocument from "@/components/request/cdrq/CDRQDocument.vue";
import {CDRQ_Owner, CDRQFileMeta, CDRQRecordStatuses} from "@/components/request/cdrq/CDQR_DTOs";
import CDRQFileHolder from "@/components/request/cdrq/CDRQFileHolder.vue";

@Component({
	computed: {
		CDRQRecordStatuses() {
			return CDRQRecordStatuses
		}
	},
	components: {CDRQFileHolder, CDRQDocument, PhoneInput, PortalInput, PortalCheckbox}
})
export default class CDRQOwnerView extends Vue {

	@Prop()
	private owner!: CDRQ_Owner

	onDelete(doc: CDRQFileMeta) {
		doc.doc = null
		doc.status = CDRQRecordStatuses.NONE
	}

}
