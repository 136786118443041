import axios from 'axios';
import {
    REQUEST_IAAR_ENDPOINT, REQUEST_IAAR_PERSON_ENDPOINT, REQUEST_IAAR_PUBLIC_PERSON_ENDPOINT
} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import PublicPersonValidPayload from "@/dto/request/iaar/PublicPersonValidPayload";
import PublicPersonNotValidPayload from "@/dto/request/iaar/PublicPersonNotValidPayload";
import IAARPersonDTO from "@/dto/request/iaar/IAARPersonDTO";
import IAAR_DTO from "@/dto/request/iaar/IAAR_DTO";

class IAARService {

    create() {
        return axios.put(REQUEST_IAAR_ENDPOINT, {}, {headers: authHeader()});
    }

    get(id: string) {
        return axios.get<IAAR_DTO>(`${REQUEST_IAAR_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    getPerson(id: string) {
        return axios.get<IAARPersonDTO>(`${REQUEST_IAAR_PERSON_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    getPublicPerson(publicId: string) {
        return axios.get<IAARPersonDTO>(`${REQUEST_IAAR_PUBLIC_PERSON_ENDPOINT}/${publicId}`);
    }

    savePublicPersonValid(publicId: string, payload: PublicPersonValidPayload) {
        return axios.put(`${REQUEST_IAAR_PUBLIC_PERSON_ENDPOINT}/${publicId}`, payload);
    }

    savePublicPersonNotValid(publicId: string, payload: PublicPersonNotValidPayload) {
        return axios.put(`${REQUEST_IAAR_PUBLIC_PERSON_ENDPOINT}/${publicId}`, payload);
    }

}

export default new IAARService();
