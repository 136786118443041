



























import { Component, Vue } from "vue-property-decorator";
import Application from "@/state/Application";
import IAARService from "@/services/request/IAARService";
import { processError } from "@/utils/ComponentUtils";
import IAARPersonDTO from "@/dto/request/iaar/IAARPersonDTO";

@Component
export default class PersonIAAR extends Vue {

  private person = new IAARPersonDTO();

  mounted(): void {
    this.loadPerson();
  }

  loadPerson(): void {
    Application.startLoading();
    IAARService.getPerson(this.iaarPersonId).then(
      ok => {
        this.person = ok.data;
        Application.stopLoading();
      },
      err => {
        Application.stopLoading();
        processError(err, this);
      }
    )
  }

  get iaarPersonId(): string {
    return this.$route.params.iaarPersonId;
  }

}
