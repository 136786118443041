import CompanyService from "@/services/CompanyService";
import { processError } from "@/utils/ComponentUtils";
import { Vue } from "vue-property-decorator";
import Application from "@/state/Application";

export default function checkLegalAddress(companyId: number, vue: Vue): Promise<boolean> {
  Application.startLoading();
  return CompanyService.getHeadQuarter(companyId).then(
    ok => {
      Application.stopLoading();
      return !!(ok.data && ok.data.legalAddress && ok.data.legalAddress.address1
        && ok.data.legalAddress.city && ok.data.legalAddress.state
        && ok.data.legalAddress.zipCode);
    },
    err => {
      Application.stopLoading();
      processError(err, vue);
      return Promise.reject();
    }
  )
}
