
class Application {
    private enableRunLoader = false;
    private texts = ['Connection is temporarily unavailable. Attempting to reconnect...',
                    'Retrying connection now. This might take a few moments.',
                    'Reconnection attempt in progress. Please hold on...',
                    'Don’t worry, your data is safe. We’ll reconnect shortly.',
                    'Reconnection in progress. Thank you for your patience!',
                    'This might take a bit longer than usual. Thank you for staying with us!',
                    'We’re working hard to speed things up. Please hang tight.',
                    'Thank you for your patience. The server should be back online soon.'];
    private _showText = false;
    private timer: null | NodeJS.Timeout = null;

    public startLoading(): void {
        if (!document.getElementById("main_spinner")) {
            const overlay = document.createElement("div");
            overlay.setAttribute("class", "overlay");
            overlay.setAttribute("id", "main_spinner");
            const div = document.createElement("div");
            div.setAttribute("class", "overlay__inner");
            const childDiv = document.createElement("div");
            childDiv.setAttribute("class", "overlay__content");
            const spinner = document.createElement("span");
            spinner.setAttribute("class", "spinner");
            childDiv.append(spinner);
            div.append(childDiv);
            overlay.append(div);
            document.getElementById("app")?.append(overlay);
        }
    }

    public stopLoading(): void {
        document.getElementById("main_spinner")?.remove();
        this.showText = false;
    }

    public changeText(): void {
        let count = 0;
        const text = document.querySelector(".text-spinner");
        (text as HTMLDivElement).innerHTML = this.texts[count];
        this.timer = setInterval(() => {
            if (count === this.texts.length - 1) {
                clearInterval(this.timer as NodeJS.Timeout);
                this.timer = null;
                return;
            }
            count += 1;
            (text as HTMLDivElement).innerHTML = this.texts[count];
        }, 10000);
    }

    get enableLoader(): boolean {
        return this.enableRunLoader;
    }

    get showText(): boolean {
        return this._showText;
    }

    set showText(value) {
        this._showText = value;
        const overlay = document.querySelector(".overlay");
        if (this._showText) {
            overlay?.classList.add("overlay_dark");
            const childDiv = document.querySelector(".overlay__content");
            const text = document.createElement("div");
            text.setAttribute("class", "text-spinner");
            childDiv?.append(text);
            this.changeText();
        } else {
            overlay?.classList.remove("overlay_dark");
            const text = document.querySelector(".text-spinner");
            text?.remove();
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
            }
        }
    }
}

export default new Application();
