




















































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import PortalInput from "@/components/common/PortalInput.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import W9CRContractorDTO from "@/dto/request/w9cr/W9CRContractorDTO";
import {InputType} from "@/components/common/InputType";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import W9CRService from "@/services/request/W9CRService";
import {FormFiller, W9CRContractorStatus} from "@/constants/request/W9CRConstants";
import {namespace} from "vuex-class";
import AutoComplete from "@/components/common/AutoComplete.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";

const AppModule = namespace("App");

@Component({
  computed: {
    FormFiller() {
      return FormFiller
    },
    AutoCompleteDTO() {
      return AutoCompleteDTO
    },
    InputType() {
      return InputType
    }
  },
  components: {SingleFileHolder, PortalInput, AutoComplete, PhoneInput}
})
export default class W9CRCompanyData extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  contractor!: W9CRContractorDTO;

  @Prop()
  filler!: FormFiller;

  emailProvided = !!this.contractor.email;

  // get isFilled() {
  //   return this.contractor.email && (this.contractor.ss4 ||
  //       this.contractor.entityName && this.contractor.entityEin && this.contractor.address.address1 && this.contractor.address.city && this.contractor.address.state
  //       && this.contractor.address.zipCode);
  // }

  get isFilled() {
    return this.contractor.email
        && this.contractor.articleOfIncorporation
        && this.contractor.ss4
        && this.contractor.addressVerification
        && this.contractor.signatoryAuthorization
        && this.contractor.voidCheck;
  }

  save() {
    this.startLoading();
    const saveFun = this.filler == FormFiller.CUSTOMER ? W9CRService.saveContractorByCustomer : W9CRService.saveContractorByContractor
    if(this.isFilled){
      this.contractor.status = W9CRContractorStatus.DOCUMENTS_PROVIDED;
    }
    saveFun(this.$route.params.id, this.contractor).then(
        ok => {
          this.stopLoading();
          console.log("OK")
        },
        err => {
          console.log(JSON.stringify(err))
          this.contractor.status = this.filler == FormFiller.CUSTOMER ? W9CRContractorStatus.FILLING_BY_CUSTOMER : W9CRContractorStatus.SENT_TO_CONTRACTOR;
          this.stopLoading();
        }
    )
  }

}
