
























































import {Component, Vue} from 'vue-property-decorator';
import Form1099NECPreparationDTO from "@/dto/preparation/Form1099NECPreparationDTO";
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import {namespace} from "vuex-class";
import CompanyDTO from "@/dto/company/CompanyDTO";
import PreparationService from "@/services/PreparationService";
import {processError} from "@/utils/ComponentUtils";
import AddForm1099NECPreparationModal from "@/components/documents/preparation/AddForm1099NECPreparationModal.vue";
import CompanyService from "@/services/CompanyService";
import {Form1099NECPreparationStatus} from "@/constants/Form1099NECPrearationConstants";
import EditForm1099NECPreparationModal from "@/components/documents/preparation/EditForm1099NECPreparationModal.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";

const AppModule = namespace("App");

@Component({
	computed: {
		Form1099NECPreparationStatus() {
			return Form1099NECPreparationStatus
		}
	},
	components: {PaginationComponent, SimpleCompanySearch}
})
export default class Form1099NECPreparations extends Vue {

	@AppModule.Action private startLoading!: () => void;
	@AppModule.Action private stopLoading!: () => void;

	selectedPayer: CompanyDTO | null = null;

	preparations: Array<Form1099NECPreparationDTO> = [];
	private pageNumber = 0
	private totalPages = 1

	hasAddress = true;

	mounted(): void {
		this.loadPreparations();
	}

	payerChanged(): void {
		this.preparations = [];
		this.checkCompanyAddress().then(
			() => {
				if(this.hasAddress){
					this.loadPreparations()
				}
			}
		)
	}

	clearPayer(): void {
		this.selectedPayer = null;
		this.pageNumber = 0;
		this.loadPreparations();
	}

	loadPreparations(): void {
		this.startLoading();
		PreparationService.get1099NECPreparations(this.pageNumber, 20, this.selectedPayer?.id).then(
			ok => {
				this.stopLoading();
				this.preparations = ok.data.content;
				this.totalPages = ok.data.totalPages;
			},
			err => {
				this.stopLoading();
				processError(err, this);
			}
		)
	}

	addPreparation(): void {
		this.$modal.show(
			AddForm1099NECPreparationModal,
			{
				payerId: this.selectedPayer!.id,
				onCreated: () => {
					this.$modal.hideAll();
					this.loadPreparations();
				}
			}
		)
	}

	checkCompanyAddress(): Promise<void> {
		this.startLoading();
		return CompanyService.getAddressById(this.selectedPayer!.id!).then(
			ok => {
				this.hasAddress = !!ok.data;
				this.stopLoading();
			},
			err => {
				this.stopLoading();
				processError(err, this);
			}
		)
	}

	editPreparation(preparation: Form1099NECPreparationDTO): void {
		this.$modal.show(
			EditForm1099NECPreparationModal,
			{
				preparation: preparation,
				onCreated: () => {
					this.$modal.hideAll();
					this.loadPreparations();
				}
			}
		)
	}

	changePage(page: number): void {
		this.pageNumber = page - 1;
		this.loadPreparations();
	}

}
