















































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import CDRQSummaryListFileRow from "@/components/request/cdrq/CDRQSummaryListFileRow.vue";
import CDRQSummarySingleFileRow from "@/components/request/cdrq/CDRQSummarySingleFileRow.vue";
import {CDQR_STEP_OPTIONS, CDRQ_DTO, CDRQStep} from "@/components/request/cdrq/CDQR_DTOs";
import {CDRQStatus} from "@/constants/request/RequestsStatuses";
import PortalSelect from "@/components/common/PortalSelect.vue";
import Application from "@/state/Application";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";

@Component({
	components: {SingleFileHolder, PortalSelect, CDRQSummarySingleFileRow, CDRQSummaryListFileRow},
	computed: {
		CDQR_STEP_OPTIONS() {
			return CDQR_STEP_OPTIONS
		},
		CDRQStatus() {
			return CDRQStatus
		},
		CDRQStep() {
			return CDRQStep
		}
	}
})
export default class CDRQSummary extends Vue {

	private resettableStep: CDRQStep | null = null

	@Prop()
	cdrq!: CDRQ_DTO;

	hasStep(step: CDRQStep): boolean {
		return this.cdrq.requiredSteps.includes(step);
	}

	private jump(step: CDRQStep) {
		this.cdrq.step = step
	}

}
