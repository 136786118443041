
















































































































































































































































































import {Vue, Component, Prop} from 'vue-property-decorator';
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import AutoComplete from "@/components/common/AutoComplete.vue";
import PersonSearch from "@/components/common/PersonSearch.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import W9CRContractorDTO from "@/dto/request/w9cr/W9CRContractorDTO";
import PersonDTO from "@/dto/person/PersonDTO";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import {
	BusinessContractorDTO, EmploymentStatus,
	IContractor,
	IndividualContractorDTO
} from "@/components/payroll/business/employments/contractors/Contractor";
import {ContractorFilter} from "@/dto/payroll/Filters";
import {CounterpartyType} from "@/constants/CounterpartyType";
import EmployeeService from "@/services/EmployeeService";
import {ifValid, processError} from "@/utils/ComponentUtils";
import {namespace} from "vuex-class";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import {W9CRContractorStatus} from "@/constants/request/W9CRConstants";
import W9CRService from "@/services/request/W9CRService";
import {IEmployment} from "@/dto/payroll/IEmployment";
import EmploymentService from "@/services/EmploymentService";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import BankAccountDTO from "@/dto/BankAccountDTO";

const AppModule = namespace("App");

@Component({
  computed: {
    AutoCompleteDTO() {
      return AutoCompleteDTO
    }
  },
  components: {PortalCheckbox, PortalInput, PhoneInput, SingleFileHolder, PersonSearch, AutoComplete, PortalCollapse}
})
export default class InternalW9CRContractor extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  contractor!: W9CRContractorDTO

  @Prop()
  onSave!: () => void

  selectedPerson: PersonDTO | null = null;
  foundContractors: Array<IContractor> = [];
  selectedContractor: IEmployment | null = null;
  contractorRestored = false;
  hasEmail = true;
  isRegistered = true;

  mounted(){
    if(this.contractor.w9Form){
      this.contractor.w9RequestRequired = false;
    }
    if(this.contractor.voidCheck){
      this.contractor.bankAccount = new BankAccountDTO();
    }
    this.restoreContractor();
    this.hasEmail = !!this.contractor.email;
    this.isRegistered = !!this.contractor.individualContractorId || !!this.contractor.businessContractorId ;
  }

  onPersonSelected(contractor: W9CRContractorDTO, person: PersonDTO): void {
    this.startLoading();
    this.contractor.individualFirstName = person.firstName!;
    this.contractor.individualLastName = person.lastName!;
    this.contractor.individualSsn = person.ssn!;
    contractor.personId = person.id;
    const filter = new ContractorFilter();
	filter.status = [EmploymentStatus.NEW, EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS]
    filter.contractorType = CounterpartyType.PERSON;
    filter.detailsId = person.id;
    EmploymentService.getPageByFilter<IContractor>(filter).then(
        ok => {
          this.foundContractors = ok.data.data.filter(e => e.employer.id == contractor.requestingCompanyId);
          if (this.foundContractors.length == 1){
            this.selectedContractor = this.foundContractors[0];
            this.contractor.individualContractorId = this.selectedContractor.id;
          }
          this.stopLoading();
        },
        err => {
          processError(err, this);
          this.stopLoading();
        }
    )
  }

  save(){
    ifValid(this, () => {
      this.contractor.status = W9CRContractorStatus.COMPLETED;
      this.startLoading();
      W9CRService.saveContractorByEmployee(this.contractor).then(
          ok => {
            this.stopLoading();
            this.onSave();
          },
          err => {
            console.log(JSON.stringify(err));
            this.stopLoading();
          }
      )
    })
  }

  individualContractorChanged(): void {
    if(this.selectedContractor) {
      this.contractor.individualContractorId = this.selectedContractor.id;
    } else {
      this.contractor.individualContractorId = null;
    }
  }

  restoreContractor(){
    switch (this.contractor.type){
      case "PERSON": this.restoreIndividualContractor(); break;
      case "COMPANY": this.restoreBusinessContractor(); break;
    }
  }

  restoreIndividualContractor(){
    if(this.contractor.individualContractorId){
      EmploymentService.getById<IndividualContractorDTO>(this.contractor.individualContractorId).then(
          ok => {
            this.selectedContractor = ok.data;
            this.contractorRestored = true;
          },
          err => processError(err, this)
      )
    }
  }

  restoreBusinessContractor(){
    if(this.contractor.businessContractorId){
      EmploymentService.getById<BusinessContractorDTO>(this.contractor.businessContractorId).then(
          ok => {
            this.selectedContractor = ok.data;
            this.contractorRestored = true;
          },
          err => processError(err, this)
      )
    }
  }

}
