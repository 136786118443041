











































































































































































import {Component, Vue} from 'vue-property-decorator';
import CDRQService from "@/services/request/CDRQService";
import Application from "@/state/Application";
import {ifValid, processError} from "@/utils/ComponentUtils";
import CDRQCard from "@/components/request/cdrq/CDRQCard.vue";
import {CDRQ_DTO, CDRQStep} from "@/components/request/cdrq/CDQR_DTOs";
import CDRQDocument from "@/components/request/cdrq/CDRQDocument.vue";
import CDRQDocumentList from "@/components/request/cdrq/CDRQDocumentList.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {STATE_LIST} from "@/constants/ProfileConstants";
import SelectOption from "@/components/common/SelectOption";
import CDRQOwnerView from "@/components/request/cdrq/CDRQOwnerView.vue";
import CDRQOwner from "@/components/request/cdrq/CDRQOwner.vue";
import CDRQDirector from "@/components/request/cdrq/CDRQDirector.vue";
import CDRQDirectorView from "@/components/request/cdrq/CDRQDirectorView.vue";
import Address from "@/components/profile/Address.vue";
import PortalAddress from "@/components/profile/branches/PortalAddress.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import AutoComplete from "@/components/common/AutoComplete.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import SoftwareAccount from "@/components/request/cdrq/SoftwareAccount.vue";
import SoftwareAccountList from "@/components/request/cdrq/SoftwareAccountList.vue";
import CDRQSummarySingleFileRow from "@/components/request/cdrq/CDRQSummarySingleFileRow.vue";
import CDRQSummaryListFileRow from "@/components/request/cdrq/CDRQSummaryListFileRow.vue";
import CDRQSummary from "@/components/request/cdrq/CDRQSummary.vue";
import CDRQComments from "@/components/request/cdrq/CDRQComments.vue";
import SignaturePage from "@/components/signature/SignaturePage.vue";
import RouteNames from "@/router/RouteNames";
import Notifications from "@/state/Notifications";

type managementMode = "OWNER" | "DIRECTOR" | null

@Component({
  methods: {
    STATE_LIST() {
      return STATE_LIST
    }
  },
  computed: {
    SelectOption() {
      return SelectOption
    },
    CDRQStep() {
      return CDRQStep
    }
  },
  components: {
    SignaturePage,
    CDRQSummary,
	  CDRQComments,
    CDRQSummaryListFileRow,
    CDRQSummarySingleFileRow,
    SoftwareAccountList,
    SoftwareAccount,
    PortalTextarea,
    PhoneInput,
    AutoComplete, PortalInput,
    PortalAddress,
    Address,
    CDRQDirectorView,
    CDRQDirector, CDRQOwner, CDRQOwnerView, PortalSelect, CDRQDocumentList, CDRQDocument, CDRQCard
  }
})
export default class CDRQ extends Vue {

  public cdrq: CDRQ_DTO | null = null

  private managerMode: managementMode = null

  protected loadCDRQ() {
    Application.startLoading()
    CDRQService.getByPublicId(this.$route.params.id).then(
        cdrq => {
          this.cdrq = cdrq.data;
          Application.stopLoading()
        },
        err => processError(err, this)
    )
  }

  public next() {
    ifValid(this, () => {
      Application.startLoading()
      CDRQService.next(this.cdrq!).then(
          step => {
            this.cdrq!.step = step.data.step;
			this.cdrq!.status = step.data.status;
			this.cdrq!.confirmation = step.data.confirmation;
            Application.stopLoading()
            this.loadCDRQ();
          },
          err => processError(err, this)
      )
    })
  }

	private reset(cdrqId: number, step: CDRQStep) {
		Application.startLoading()
		CDRQService.resetStep(cdrqId, step).then(
			() => {
				Application.stopLoading()
				Notifications.success("The request has been successfully reset!")
				this.$router.push({name: RouteNames.REQUESTS})
			},
			err => processError(err, this)
		)
	}

  public back() {
    ifValid(this, () => {
      Application.startLoading()
      CDRQService.back(this.cdrq!).then(
          step => {
	          this.cdrq!.step = step.data.step;
	          this.cdrq!.status = step.data.status;
	          this.cdrq!.confirmation = step.data.confirmation;
            Application.stopLoading()
          },
          err => processError(err, this)
      )
    })
  }

  public created() {
    this.loadCDRQ()
  }

  deleteOwner(uuid: string) {
    this.cdrq!.management.owners = this.cdrq!.management.owners.filter(it => it.uuid != uuid)
  }

  deleteDirector(uuid: string) {
    this.cdrq!.management.directors = this.cdrq!.management.directors.filter(it => it.uuid != uuid)
  }

	complete() {
	  if (this.$wss.isUserSublimeEmployee) {
		  Application.startLoading()
		  CDRQService.complete(this.cdrq!).then(
			  () => {
				  Application.stopLoading()
				  Notifications.success("The request status has been successfully updated!")
				  this.$router.push({name: RouteNames.REQUESTS})
			  },
			  err => processError(err, this)
		  )
	  }
	}


}

