











































import {Component, Prop, Vue} from "vue-property-decorator";
import DocumentService from "@/services/DocumentService";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import RequestIT2104RequestDTO from "@/dto/archive/RequestIT2104RequestDTO";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import { ifValid, processError } from "@/utils/ComponentUtils";
import {IEmployment} from "@/dto/payroll/IEmployment";
import EmployeeService from "@/services/EmployeeService";
import {EmployeeFilter} from "@/dto/payroll/Filters";
import CompanyService from "@/services/CompanyService";
import BranchDto from "@/components/profile/branches/BranchDtos";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import checkLegalAddress from "@/utils/CheckLegalAddress";

const AppModule = namespace("App");

@Component({
  methods: {
    checkLegalAddress,
  },
	computed: {
		SelectOption() {
			return SelectOption
		}
	},
	components: {PortalSelect}
})
export default class RequestIT2104Modal extends Vue {

	@AppModule.State
	private loading!: boolean;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

  @Prop()
  private companyId!: number;

  @Prop()
  private onSaved!: (sublimeFilling: boolean, id: number) => void;

	private request = new RequestIT2104RequestDTO();

  private successful = false;

  private message = "";

	private employees: Array<IEmployment> = [];

  private branches: BranchDto[] = [];

  hasOfficer = true;

  hasLegalAddress: boolean | void = true;

	created(): void {
		this.request.employerId = this.companyId;
    this.loadEmployees();
	}

	loadEmployees(): void {
		this.startLoading();
		const filter = new EmployeeFilter();
		filter.employerId = this.companyId;
		filter.pageSize = 100;
		filter.status = [EmploymentStatus.NEW, EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS]
		EmployeeService.findByFilter(filter).then(
			res => {
				this.employees = res.data.data;
				this.successful = true;
			},
			err => {
				this.errorHandle(err)
			}
		)
    .then(() => this.loadBranches(this.companyId))
    .then(async () => {
      await this.checkOfficerPresence();
      this.hasLegalAddress = await checkLegalAddress(this.companyId, this);
    })
    .then(() => this.stopLoading());
	}

	private save(): void {
		ifValid(this, () => {
			this.message = "";
      this.startLoading();
      DocumentService.registerIT2104(this.request).then(
        (ok) => {
          this.successful = true;
          this.onSaved(this.request.sublimeFilling, ok.data.id);
          this.$modal.hideAll();
        },
        error => {
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        }
      ).then(() => this.stopLoading());
		})
	}

	private errorHandle(error: any): void {
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
		this.successful = false;
	}

  private checkOfficerPresence() {
    return CompanyService.getOfficer(this.companyId).then(
      ok => {
        this.hasOfficer = !!ok.data;
      },
      err => {
        processError(err, this);
      }
    )
  }

  private loadBranches(id: number) {
    return CompanyService.getBranches(id).then(
      ok => {
      this.branches = ok.data;
      },
      err => {
        processError(err, this);
      },
    );
  }

}
