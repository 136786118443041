










































































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import PhoneInput from "@/components/util/PhoneInput.vue";
import BankAccountDTO from "@/dto/BankAccountDTO";
import Workspace, {WorkspaceType} from "@/dto/auth/Workspace";
import Workspaces from "@/state/Workspaces";
import CompanyService from "@/services/CompanyService";
import PersonService from "@/services/PersonService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import SignableDocumentDTO from "@/dto/files/SignableDocumentDTO";
import PortalInput from "@/components/common/PortalInput.vue";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import {STATE_LIST} from "@/constants/ProfileConstants";
import RouteNames from "@/router/RouteNames";
import {processError, validate} from "@/utils/ComponentUtils";
import DocumentService from "@/services/DocumentService";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import axios from "axios";
import {PERSON_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";
import Notifications from "@/state/Notifications";

const AppModule = namespace("App");

@Component({
	computed: {
		SelectOption() {
			return SelectOption
		},
		states(): Array<string> {
			return STATE_LIST;
		},
		AutoCompleteDTO() {
			return AutoCompleteDTO
		}
	},
	components: {PortalRadio, PortalSelect, SingleFileHolder, AutoComplete, PortalInput, PhoneInput }
})
export default class SublimeOffice extends Vue {
	private bankAccount: BankAccountDTO = new BankAccountDTO();

	private message = "";

	private successful = false;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private isUserSublimeEmployee: boolean = Workspaces.isUserSublimeEmployee;

	private workspace: Workspace = Workspaces.getCurrent as Workspace;

	public created(): void {
		if (!Number.parseInt(this.$route.params.accountId, 10)) {
			this.$router.push({name: RouteNames.PROFILE_BANK_ACCOUNTS})
		}
		this.loadBankAccount();
	}

	private loadBankAccount() {
		switch (this.workspace.type) {
			case WorkspaceType.PERSON:
				this.fetchBankAccountForPerson()
				break;
			case WorkspaceType.COMPANY:
				this.fetchBankAccountForCompany()
				break;
		}
	}

	private deleteAccount(): void {
		this.$modal.show(
			DeleteConfirmation,
			{
				targetName: "bank account",
				onDelete: () => this.deleteBankAccount()
			}
		)
	}

	private deleteBankAccount() {
		const accountDeleted = () => {
			Notifications.success("The bank account has been deleted.")
			this.$router.push(
				{
					name: RouteNames.PROFILE_BANK_ACCOUNTS,
					params: {
						wsType: Workspaces.getCurrent.type,
						wsId: Workspaces.getCurrent.id.toString()
					}
				}
			)
		}
		switch (this.workspace.type) {
			case WorkspaceType.COMPANY:
				CompanyService.deleteBankAccount(this.bankAccount.id).then(
					() => accountDeleted(),
					err => processError(err, this)
				);
				break;
			case WorkspaceType.PERSON:
				PersonService.deleteBankAccount(this.bankAccount.id).then(
					() => accountDeleted(),
					err => processError(err, this)
				)
		}
	}

	private deleteAchForm() {
		this.startLoading();
		this.message = "";
		validate(this).then(
			isValid => {
				if (isValid) {
					this.message = '';
					this.startLoading();
					switch (this.workspace.type) {
						case WorkspaceType.PERSON:
							PersonService.deleteAchForm(this.bankAccount.id).then(
								() => {
									this.processResp();
									this.loadBankAccount();
									this.message = "The ach from was successfully deleted";
								},
								err => this.processError(err)
							)
							break;
						case WorkspaceType.COMPANY:
							CompanyService.deleteAchForm(this.bankAccount.id).then(
								() => {
									this.processResp();
									this.loadBankAccount();
									this.message = "The ach from was successfully deleted";
								},
								err => this.processError(err)
							)
							break;
					}
				}
			}
		)
	}

	private reqAch(): void {
		this.message = "";

		if (!this.bankAccount.verified) {
			this.successful = false;
			this.message = "This bank account hasn't passed verification!";
		} else {
			this.startLoading();
			DocumentService.requestAch(this.bankAccount.id, this.workspace.type).then(
				() => {
					this.successful = true;
					this.stopLoading();
					this.loadBankAccount()
				},
				err => this.processError(err)
			)
		}

	}

	private update(): void {
		this.message = "";
		validate(this).then(
			isValid => {
				if (isValid) {
					this.message = '';
					this.startLoading();
					switch (this.workspace.type) {
						case WorkspaceType.PERSON:
							this.updateBankAccountForPerson()
							break;
						case WorkspaceType.COMPANY:
							this.updateBankAccountForCompany()
							break;
					}
				}
			}
		)
	}

	private verify(): void {
		this.message = "";
		switch (this.workspace.type) {
			case WorkspaceType.PERSON:
				this.verifyForPerson()
				break;
			case WorkspaceType.COMPANY:
				this.verifyForCompany()
				break;
		}
	}

	private verifyForCompany(): void {
		this.startLoading();
		CompanyService.verify(this.bankAccount.id, true).then(
			() => {
				this.successful = true;
				this.stopLoading();
				this.message = "The bank account was successfully approved!";
				this.loadBankAccount()
			},
			err => this.processError(err)
		)
	}

	private verifyForPerson(): void {
		this.startLoading();
		PersonService.verify(this.bankAccount.id, true).then(
			() => {
				this.successful = true;
				this.stopLoading();
				this.message = "The bank account was successfully approved!";
				this.loadBankAccount()
			},
			err => this.processError(err)
		)
	}

	private download(file: FileMetaDTO) {
		Vue.prototype.$docUtils.download(file.id)
	}

	private fetchBankAccountForPerson() {
		this.startLoading()
		PersonService.getBankAccountById(Number.parseInt(this.$route.params.accountId, 10), this.workspace.id).then(
			res => this.processResp(res.data),
			err => this.processError(err)
		)
	}

	private fetchBankAccountForCompany() {
		this.startLoading()
		CompanyService.getBankAccountById(Number.parseInt(this.$route.params.accountId, 10), this.workspace.id).then(
			res => this.processResp(res.data),
			err => this.processError(err)
		)
	}

	private processError(err: any) {
		this.successful = false;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
		this.stopLoading();
	}

	private processResp(account: BankAccountDTO | null = null) {
		if (account) {
			this.bankAccount = account;
			if (account.achForm)
				this.bankAccount.manualForm = account.achForm.form
		}
		this.successful = true;
		this.stopLoading();
	}

	private updateBankAccountForPerson() {
		this.bankAccount.personId = this.workspace.id;
		PersonService.updateBankAccount(this.bankAccount).then(
			() => {
				this.processResp();
				this.loadBankAccount();
				this.message = "The bank account was successfully updated";
			},
			err => this.processError(err)
		)
	}

	private updateBankAccountForCompany() {
		this.bankAccount.companyId = this.workspace.id
		CompanyService.updateBankAccount(this.bankAccount).then(
			() => {
				this.processResp();
				this.loadBankAccount();
				this.message = "The bank account was successfully updated";
			},
			err => this.processError(err)
		)
	}

	get activeOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title("Active account").value(true).build(),
			SelectOption.builder().title("Inactive account").value(false).build()
		]
	}
};
