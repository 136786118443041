import FileMetaDTO from "@/dto/files/FileMetaDTO";
import NewInvoiceDTO from "@/dto/fincen/NewInvoiceDTO";

export default class CreateStatisticalReportPayload {
    companyId!: number;
    type!: string;
    year!: number;
    state!: string;
    file!: FileMetaDTO;
    filingDate?: Date;
    paid = true;
    invoice: NewInvoiceDTO | null = new NewInvoiceDTO();

    constructor(init?: Partial<CreateStatisticalReportPayload>) {
        Object.assign(this, init);
    }
}
