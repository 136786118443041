





















































































































import {Component, Vue} from 'vue-property-decorator';
import CompanySearch from "@/components/common/CompanySearch.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {
	CDQR_STEP_OPTIONS,
	CDRQFileMeta,
	CDRQRecordStatuses, CDRQStep,
	CreateCDRQPayload
} from "@/components/request/cdrq/CDQR_DTOs";
import Notifications from "@/state/Notifications";
import {ifValid, processError} from "@/utils/ComponentUtils";
import CDRQService from "@/services/request/CDRQService";
import Application from "@/state/Application";
import CDRQDocument from "@/components/request/cdrq/CDRQDocument.vue";
import CDRQDocumentList from "@/components/request/cdrq/CDRQDocumentList.vue";
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";

@Component({
	computed: {
		CDRQStep() {
			return CDRQStep
		},
		CDQR_STEP_OPTIONS() {
			return CDQR_STEP_OPTIONS
		}
	},
	components: {PortalCheckbox, SimpleCompanySearch, CDRQDocumentList, CDRQDocument, PortalSelect, CompanySearch}
})
export default class CreateCDQR extends Vue {

	private req = new CreateCDRQPayload()

	private create() {
		if (!this.req.company) {
			Notifications.error("Validation error! No company. Please, select the company from the list!")
		} else {
			ifValid(this, () => {
				Application.startLoading()
				CDRQService.create(this.req).then(
					() => {
						Application.stopLoading()
						Notifications.success("The request has been created!")
						this.$emit("create", this.req);
					},
					err => processError(err, this)
				)
			})
		}
	}

}

