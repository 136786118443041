



















import {Vue, Component, VModel, Prop} from 'vue-property-decorator';
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import EmbeddedFile from "@/components/common/EmbeddedFile.vue";
import FileUpload from "@/components/common/FileUpload.vue";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";

@Component({
	components: {FileUpload, EmbeddedFile}
})
export default class CompanyDocument extends Vue {

	@VModel({type: FileMetaDTO, default: null})
	private doc!: FileMetaDTO | null

	onFileLoaded(files: FileMetaDTO[]) {
		const file = files[0];
		this.$emit("input", file)
		this.$emit("save", file)
	}

	confirmDelete() {
		this.$modal.show(
			DeleteConfirmation,
			{
				targetName: this.doc!.fileName,
				onDelete: () => {
					this.$emit("input", null)
					this.$emit('delete')
				}
			}
		)
	}
}
