


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ITRQ_DTO, { ITRQDependent, ITRQDocumentDTO, ITRQResidenceAddress } from "@/dto/request/itrq/ITRQ_DTO";
import IndividualTaxationService from "@/services/request/IndividualTaxationService";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import { MaritalStatus } from "@/dto/taxation/individual/MaritalStatus";
import { ITRQStep, ORDERED_STEPS } from "@/constants/request/ITRQStep";
import { ifValid, processError } from "@/utils/ComponentUtils";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import { ITRQDocumentType } from "@/constants/request/ITRQDocumentType";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import ITRQSummary from "@/components/request/itrq/ITRQSummary.vue";
import {ITRQStatus} from "@/constants/request/RequestsStatuses";
import { RelationshipToTaxpayer } from "@/constants/request/ITRQConstants";
import { HolderType } from "@/dto/HolderType";
import { prettyEnum } from "@/utils/StringUtils";
import PortalDate from "@/components/common/PortalDate.vue";
import { ITRQDeductionsDocumentType } from "@/constants/request/ITRQDeductionsDocumentType";
import { BankAccountType } from "@/dto/BankAccountType";
import SignaturePage from "@/components/signature/SignaturePage.vue";

const AppModule = namespace("App");

const singleDocumentsTypes = [ITRQDocumentType.SSN, ITRQDocumentType.ID_FRONT, ITRQDocumentType.ID_BACK, ITRQDocumentType.SPOUSE_SSN,
  ITRQDocumentType.SPOUSE_ID_FRONT, ITRQDocumentType.SPOUSE_ID_BACK, ITRQDocumentType.FORM_1095_A, ITRQDocumentType.FORM_1095_B, ITRQDocumentType.FORM_1095_C]

@Component<CustomerITRQ>({
  components: {
    SignaturePage,
    PortalDate,
    ITRQSummary,
    SingleFileHolder,
    PortalCheckbox,
    AutoComplete,
    PortalSelect,
    PortalInput,
    PortalCollapse
  },
  methods: {
    prettyEnum,
  },
  computed: {
    FileMetaDTO() {
      return FileMetaDTO
    },
    AutoCompleteDTO() {
      return AutoCompleteDTO
    },
    ITRQStep() {
      return ITRQStep
    },
    MaritalStatus() {
      return MaritalStatus
    },
    SelectOption() {
      return SelectOption
    },
    DocumentType() {
      return ITRQDocumentType
    }
  },
  watch: {
    isSpecifyBankAccount(n) {
      if (!n) {
        this.q!.bankAccount = {
          accountNumber: null,
          routingNumber: null,
          accountType: null,
          holderType: null,
        }
      }
    }
  }
})
export default class CustomerITRQ extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private q: ITRQ_DTO | null = null;
  private singleDocuments: Record<ITRQDocumentType, FileMetaDTO | null> = {} as Record<ITRQDocumentType, FileMetaDTO | null>;
  private listDocuments: Record<ITRQDocumentType, Array<FileMetaDTO>> = {} as Record<ITRQDocumentType, Array<FileMetaDTO>>;
  private documentNulls: Record<ITRQDocumentType, FileMetaDTO | null> = {} as Record<ITRQDocumentType, FileMetaDTO | null>;
  private newIRSLetter: FileMetaDTO | null = null;
  private deductionsDocuments: Record<ITRQDocumentType, Array<ITRQDocumentDTO>> = {} as Record<ITRQDocumentType, Array<ITRQDocumentDTO>>;
  private newDeductionsDocument: Record<ITRQDocumentType, FileMetaDTO | null> = {} as Record<ITRQDocumentType, FileMetaDTO | null>;
  private isSpecifyBankAccount = false;

  mounted(): void {
    this.loadQuestionnaire();
  }

  loadQuestionnaire(): void {
    this.startLoading();
    IndividualTaxationService.getQuestionnaireByPublicId(this.id).then(
        ok => {
          this.initDocuments(ok.data.documents);
          this.q = ok.data;
          this.q.residenceAddresses.sort((a, b) => a.order - b.order);
          this.isSpecifyBankAccount = this.isBankAccount;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
    )
  }

  initDocuments(docs: Array<ITRQDocumentDTO>): void {
    Object.values(ITRQDocumentType).forEach(t => {
      if (!singleDocumentsTypes.includes(t)) {
        this.listDocuments[t] = []
      }
    })
    docs.forEach(d => {
      if (singleDocumentsTypes.includes(d.type)) {
        this.singleDocuments[d.type] = d.file
      } else {
        this.listDocuments[d.type].push(d.file)
      }
    });
  }

  saveSpecificStep(step: ITRQStep): void {
    const actualStep = this.step;
    this.q!.step = step;
    ifValid(this, () => {
      if ((this.isFDStep && !this.validDependents) || (this.isAddressStep && !this.validAddresses)) {
        return;
      }
      this.q!.step = actualStep;
      this.save()?.then(
          () => {
            this.$void();
          }
      )
    })
  }

  save() {
    if (!this.q) {
      return;
    }
    this.q.language = this.$i18n.locale.toUpperCase();
    this.startLoading();
    return IndividualTaxationService.updateQuestionnaireByPublicId(this.id, this.q).then(
        ok => {
          this.stopLoading();
          return Promise.resolve(ok);
        },
        err => {
          this.stopLoading();
          processError(err, this);
          return Promise.reject(err);
        }
    )
  }

  onClickNext() {
    ifValid(this, () => {
      if ((this.isFDStep && !this.validDependents) || (this.isAddressStep && !this.validAddresses)) {
        return;
      }
      this.setStep(this.getNextStep())();
    })
  }

  setStep(step: ITRQStep): () => void {
    return () => {
      this.q!.step = step;
      this.save();
    }
  }

  getNextStep(): ITRQStep {
    const currentIndex = ORDERED_STEPS.indexOf(this.step);
    if (currentIndex == ORDERED_STEPS.length - 1) {
      return this.step;
    } else {
      return ORDERED_STEPS[currentIndex + 1]
    }
  }

  isStepPassed(targetStep: ITRQStep): boolean {
    return ORDERED_STEPS.indexOf(this.step) > ORDERED_STEPS.indexOf(targetStep);
  }

  isCurrentStepOrPassed(targetStep: ITRQStep): boolean {
    return ORDERED_STEPS.indexOf(this.step) >= ORDERED_STEPS.indexOf(targetStep);
  }

  saveDocument(type: ITRQDocumentType, file: FileMetaDTO): Promise<void> {
    this.startLoading();
    return IndividualTaxationService.saveDocumentByPublicId(this.id, type, file).then(
      ok => {
        if(!singleDocumentsTypes.includes(type)){
          this.listDocuments[type].push(file);
          this.documentNulls[type] = null;
        }
        this.stopLoading();
      },
      err => {
        processError(err, this);
        this.stopLoading();
      }
    )
  }

  confirm() {
    this.q!.status = ITRQStatus.PENDING_FOR_SIGNED_CONFIRMATION;
    this.save()?.then(
        ok => this.loadQuestionnaire()
    );
  }

  addDependent(): void {
    this.q?.dependents.push(new ITRQDependent());
  }

  deleteDependent(deleteIndex: number): void {
    this.q!.dependents = this.q!.dependents.filter((item, index) => index !== deleteIndex);
  }

  addAddress(): void {
    this.q?.residenceAddresses.push(new ITRQResidenceAddress({ order: this.q?.residenceAddresses.length + 1 }));
  }

  deleteAddress(deleteIndex: number): void {
    this.q!.residenceAddresses.splice(deleteIndex, 1);
    this.q!.residenceAddresses = this.q!.residenceAddresses.map((item, index) => {
      return { ...item, order: index + 1 };
    });
  }

  changeResidenceDate(residenceAddress: ITRQResidenceAddress) {
    if (residenceAddress.residenceOnPeriodStart) {
      residenceAddress.from = null;
    }
    if (residenceAddress.current) {
      residenceAddress.to = null;
    }
  }

  get isAddressCurrentStepOrPassed(): boolean {
    const result = ORDERED_STEPS.indexOf(this.step) >= ORDERED_STEPS.indexOf(ITRQStep.ADDRESS);
    if (result) {
      if (!this.q?.residenceAddresses.length) {
        this.q?.residenceAddresses.push(new ITRQResidenceAddress({ order: 1 }));
      }
    }
    else {
      if (this.q?.residenceAddresses.length === 1 &&
        (!this.q.residenceAddresses[0].address1 || !this.q.residenceAddresses[0].city
          || !this.q.residenceAddresses[0].state || !this.q.residenceAddresses[0].zipCode)) {
        this.q.residenceAddresses.pop();
      }
    }
    return result;
  }

  get validDependents(): boolean {
    for (let dependent of this.q!.dependents) {
      if (!((dependent.certificateOfBirth || dependent.stateIdFront) && dependent.ssn && (dependent.livedWithEntireYear
        || (dependent.livedMonths !== null ? 0 < dependent.livedMonths && dependent.livedMonths < 12 : false)))) {
        return false;
      }
    }
    return true;
  }

  get validAddresses(): boolean {
    return !!this.q?.residenceAddresses.length;
  }

  get id() {
    return this.$route.params.id;
  }

  get isFillingStatus(): boolean {
    if(!this.q){
      return false;
    }
    return [ITRQStatus.SENT, ITRQStatus.OPENED, ITRQStatus.BEING_FILLED_OUT, ITRQStatus.PENDING_FOR_CONFIRMATION].includes(this.q.status);
  }

  get isPandaConfirmation(): boolean {
    return this.q?.status == ITRQStatus.PENDING_FOR_SIGNED_CONFIRMATION;
  }

  get isConfirmed(): boolean {
    return this.q?.status == ITRQStatus.CONFIRMED;
  }

  get isPDStep(): boolean {
    return this.q?.step == ITRQStep.TAXPAYER_INFORMATION;
  }

  get isFDStep(): boolean {
    return this.q?.step == ITRQStep.FILING_STATUS;
  }

  get isMarried(): boolean {
    return this.q?.maritalStatus == MaritalStatus.MARRIED;
  }

  get isAddressStep(): boolean {
    return this.q?.step == ITRQStep.ADDRESS;
  }

  get isOccupationStep(): boolean {
    return this.q?.step == ITRQStep.OCCUPATION;
  }

  get isIncomeFormsStep(): boolean {
    return this.q?.step == ITRQStep.INCOME;
  }

  get isOnlineMerchantIncomeStep(): boolean {
    return this.q?.step == ITRQStep.ONLINE_MERCHANT_INCOME
  }

  get isAnyOnlineMerchantChecked(): boolean {
    const o = this.q!.onlineMerchantIncome;
    return o.zelle || o.airbnb || o.amazon || o.etsy || o.lyft || o.paypal || o.square || o.uber || o.venmo || o.vrbo || o.otherOnlineStore || o.otherPointOfSale || o.other;
  }

  get isNoOnlineIncomeChecked(): boolean {
    return this.q!.onlineMerchantIncome.noIncome;
  }

  get isAnyOnlineMerchantOrNoneChecked(): boolean {
    return this.isAnyOnlineMerchantChecked || this.isNoOnlineIncomeChecked;
  }

  get isDeductionsStep(): boolean {
    return this.q?.step == ITRQStep.DEDUCTIONS;
  }

  get isInsuranceStep(): boolean {
    return this.q?.step == ITRQStep.INSURANCE;
  }

  get isForeignBankAccountStep(): boolean {
    return this.q?.step == ITRQStep.FOREIGN_BANK_ACCOUNT;
  }

  get isCryptoStep(): boolean {
    return this.q?.step == ITRQStep.CRYPTO;
  }

  get isStockTradeStep(): boolean {
    return this.q?.step == ITRQStep.STOCK_TRADE;
  }

  get isIRSLettersStep(): boolean {
    return this.q?.step == ITRQStep.IRS_LETTERS;
  }

  get isBankAccountStep(): boolean {
    return this.q?.step == ITRQStep.BANK_ACCOUNT;
  }

  get isEstimatedTaxPaymentsStep(){
    return this.q?.step == ITRQStep.ESTIMATED_TAX_PAYMENTS;
  }

  get isSummaryStep(): boolean {
    return this.q?.step == ITRQStep.SUMMARY;
  }

  get step() {
    return this.q!.step;
  }

  get maritalStatusOptions(): SelectOption[] {
    return Object.values(MaritalStatus).map(m => SelectOption.builder().title(this.$t(`profile.marital_statuses.${m}`) as string).value(m).build());
  }

  get dependentRelationsOptions(): SelectOption[] {
    return Object.values(RelationshipToTaxpayer).map(m => SelectOption.builder().title(this.$t(`requests.itrq.relationships_to_taxpayer.${m}`) as string).value(m).build());
  }

  get accountTypeOptions(): SelectOption[] {
    return Object.values(BankAccountType).map(m => SelectOption.builder().title(prettyEnum(m)).value(m).build());
  }

  get holderTypeOptions(): SelectOption[] {
    return Object.values(HolderType).map(m => SelectOption.builder().title(prettyEnum(m)).value(m).build());
  }

  get isBankAccount(): boolean {
    return !!(this.q?.bankAccount.accountNumber || this.q?.bankAccount.routingNumber || this.q?.bankAccount.accountType || this.q?.bankAccount.holderType);
  }

}

