
























import {Component, VModel, Vue} from 'vue-property-decorator';
import CDRQRecordStatus from "@/components/request/cdrq/CDRQRecordStatus.vue";
import {AccountingSoftware, CDRQAccountingSoftwareList, CDRQRecordStatuses} from "@/components/request/cdrq/CDQR_DTOs";
import SoftwareAccount from "@/components/request/cdrq/SoftwareAccount.vue";
import Notifications from "@/state/Notifications";

@Component({
	computed: {
		CDRQRecordStatuses() {
			return CDRQRecordStatuses
		}
	},
	components: {SoftwareAccount, CDRQRecordStatus}
})
export default class SoftwareAccountList extends Vue {

	@VModel({type: CDRQAccountingSoftwareList, required: true})
	private accList!: CDRQAccountingSoftwareList

	private deleteAcc(acc: AccountingSoftware) {
		this.accList.softwareAccounts = this.accList.softwareAccounts.filter(it => it.uuid != acc.uuid)
	}

	private toggle() {
		this.modifiable = !this.modifiable
	}

	private modifiable = false;

	public created(): void {
		this.$validator.extend(
			"requiredSoftwareAccs",
			{
				validate: value => {
					if (value.softwareAccounts.length < 1 && value.status == CDRQRecordStatuses.OK) {
						Notifications.error(`${value.title} is empty!`)
						return false
					} else {
						return true
					}
				}
			}
		)
	}
}
