







































import {Component, Prop, Vue} from 'vue-property-decorator';
import EmploymentSearch from "@/components/common/EmploymentSearch.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PreparationService from "@/services/PreparationService";
import {namespace} from "vuex-class";
import {ifValid, processError} from "@/utils/ComponentUtils";
import Form1099NECPreparationDTO from "@/dto/preparation/Form1099NECPreparationDTO";
import EditForm1099NECPreparationPayload from "@/dto/preparation/EditForm1099NECPreparationPayload";
import {Form1099NECPreparationStatus, InternalSaveAction} from "@/constants/Form1099NECPrearationConstants";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import TextFieldModal from "@/components/common/TextFieldModal.vue";

const AppModule = namespace("App");

@Component({
  components: {SingleFileHolder, PortalCheckbox, PortalInput, EmploymentSearch},
  computed: {
    InternalSaveAction() {
      return InternalSaveAction;
    }
  }
})
export default class EditForm1099NECPreparationModal extends Vue {

  @AppModule.Action private startLoading!: () => void;
  @AppModule.Action private stopLoading!: () => void;

  @Prop()
  preparation!: Form1099NECPreparationDTO;

  repreparationRequired = false

  payload = new EditForm1099NECPreparationPayload({
    action: (() => {
      switch (this.preparation.status) {
        case Form1099NECPreparationStatus.DRAFT_PREPARED:
          return InternalSaveAction.SEND;
        case Form1099NECPreparationStatus.DRAFT_REJECTED:
          return InternalSaveAction.REPREPARE;
        case Form1099NECPreparationStatus.DRAFT_APPROVED:
          return InternalSaveAction.SUBMIT;
        case Form1099NECPreparationStatus.FILED:
          return InternalSaveAction.COMPLETE;
      }
    })(),
    corrected: this.preparation.corrected,
    amount: this.preparation.amount,
    resale: this.preparation.resale,
    federalWithheld: this.preparation.federalWithheld,
    stateWithheld1: this.preparation.stateWithheld1,
    stateWithheld2: this.preparation.stateWithheld2,
    payerStateNo1: this.preparation.payerStateNo1,
    payerStateNo2: this.preparation.payerStateNo2,
    stateIncome1: this.preparation.stateIncome1,
    stateIncome2: this.preparation.stateIncome2
  });

  @Prop()
  onCreated!: () => void

  save(action: InternalSaveAction): void {
    this.payload.action = action;
    ifValid(this, () => {
      this.startLoading();
      PreparationService.save1099NECPreparation(this.preparation.id, this.payload).then(
          ok => {
            this.onCreated();
            this.stopLoading();
          },
          err => {
            processError(err, this);
            this.stopLoading();
          }
      )
    });
  }

  reprepare(): void {
    this.$modal.show(
        TextFieldModal,
        {
          title: "Repreparation reason",
          required: true,
          onOk: (text: string) => { this.payload.repreparationReason = text; this.save(InternalSaveAction.REPREPARE); }

        }
    )
  }


}
