export default class CreateITRQPayloadDTO {
    personId: number | null = null;
    year: number | null = null;
    personFirstName: string | null = null;
    personLastName: string | null = null;
    email: string | null = null;
    phone: string | null = null;

    constructor(init?: Partial<CreateITRQPayloadDTO>) {
        Object.assign(this, init);
    }
}
