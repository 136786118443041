















import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import SelectOption from "@/components/common/SelectOption";
import {v4, v4 as uuidv4} from "uuid";
import {PropType} from "vue";
import {Md5} from "ts-md5";

@Component({
	methods: {v4}
})
export default class PortalRadio extends Vue {

	@Prop({default: "Time format"})
	private title!: string | null;

	@VModel( {type: [Object, Array, String, Boolean, Number, null] as PropType<any>, default: null})
	private select!: any;

	@Prop({default: function () {return uuidv4()}})
	private id!: string

	@Prop({default: "#436356"})
	private activeColor!: string;

	isSelected(option: SelectOption): boolean {
		return Md5.hashStr(JSON.stringify(this.select)) == Md5.hashStr(JSON.stringify(option.value))
	}

	@Prop({default: function () {return []}})
	private options!: Array<SelectOption>

	get optionsArr(): Array<SelectOption> {
		return this.options
	}

	private click(option: SelectOption): void {
		this.$emit('input', option.value)
	}

}
