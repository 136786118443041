import {
    CDRQ_DTO,
    CDRQStep,
    CreateCDRQCommentPayload,
    CreateCDRQPayload,
    MetaCDRQ
} from "@/components/request/cdrq/CDQR_DTOs";
import axios, {AxiosResponse} from "axios";
import {CDRQ_ENDPOINT, CDRQ_PUBLIC_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";

class CDRQService {
    public create(payload: CreateCDRQPayload): Promise<AxiosResponse<void>> {
        return axios.post(`${CDRQ_ENDPOINT}`, payload, {headers: authHeader()})
    }

    public getByInternalId(id: string): Promise<AxiosResponse<CDRQ_DTO>> {
        return axios.get<CDRQ_DTO>(`${CDRQ_ENDPOINT}/${id}`, {headers: authHeader()})
    }


    public getByPublicId(publicId: string): Promise<AxiosResponse<CDRQ_DTO>> {
        return axios.get<CDRQ_DTO>(`${CDRQ_PUBLIC_ENDPOINT}/${publicId}`, {headers: authHeader()})
    }

    public next(cdrq: CDRQ_DTO): Promise<AxiosResponse<MetaCDRQ>> {
        return axios.put<MetaCDRQ>(`${CDRQ_PUBLIC_ENDPOINT}/next`, cdrq, {headers: authHeader()})
    }

    public back(cdrq: CDRQ_DTO): Promise<AxiosResponse<MetaCDRQ>> {
        return axios.put<MetaCDRQ>(`${CDRQ_PUBLIC_ENDPOINT}/back`, cdrq, {headers: authHeader()})
    }

    public sendComment(payload: CreateCDRQCommentPayload): Promise<AxiosResponse<void>> {
        return axios.post<void>(`${CDRQ_PUBLIC_ENDPOINT}/comment`, payload, {headers: authHeader()})
    }

    public complete(cdrq: CDRQ_DTO) {
        return axios.put<void>(`${CDRQ_ENDPOINT}/${cdrq.id}/complete`, {}, {headers: authHeader()})
    }

    public resetStep(cdrqId: number, step: CDRQStep) {
        return axios.put<void>(`${CDRQ_ENDPOINT}/${cdrqId}/reset?step=${step}`, {}, {headers: authHeader()})
    }
}

export default new CDRQService();