















import {Component, Prop, Vue} from 'vue-property-decorator';
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import PersonSearch from "@/components/common/PersonSearch.vue";
import RequestPersonSignatureForFilledDocumentModal from "@/components/documents/signing/RequestPersonSignatureForFilledDocumentModal.vue";
import RequestCompanySignatureForFilledDocumentModal from "@/components/documents/signing/RequestCompanySignatureForFilledDocumentModal.vue";


@Component({
  components: {PersonSearch, PortalSelect, SingleFileHolder, SimpleCompanySearch}
})
export default class SelectProfileTypeForPrefilledDocumentModal extends Vue {

  @Prop()
  onCreated!: () => void

  select(type: "PERSON" | "COMPANY"): void {
    this.$modal.hideAll();
    switch (type) {
      case "PERSON":
        this.$modal.show(
            RequestPersonSignatureForFilledDocumentModal,
            {
              onCreated: this.onCreated
            }
        );
        break;
      case "COMPANY":
        this.$modal.show(
            RequestCompanySignatureForFilledDocumentModal,
            {
              onCreated: this.onCreated
            }
        );
        break;
    }
  }


}
