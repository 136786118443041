import AddressDTO from "@/dto/AddressDTO";
import {IProfile} from "@/dto/IProfile";
import {AccountType} from "@/constants/AccountTypes";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {PersonSex, ResidencyStatus, SSNType} from "@/constants/PersonConstants";

export default class PersonDTO implements IProfile{

    public id: number | null = null;

    public userId: number | null = null;

    public email: string | null = null;

    public firstName: string | null = null;

    public lastName: string | null = null;

    public birthDate: Date | null = null;

    public sex: PersonSex | null = null;

    public countryOfBirth: string | null = null;

    public nationality: string | null = null;

    public residencyStatus: ResidencyStatus | null = null;

    public nationalTin: string | null = null;

    public declaredSsn: string | null = null;

    public ssn: string | null = null;

    public ssnCard: FileMetaDTO | null = null;

    public ssnType: SSNType | null = null;

    public declaredItin: string | null = null;

    public itin: string | null = null;

    public phone: string | null = null;

    public client: boolean | null = null;

    public isClient = false;

    public maritalStatus: string | null = null;

    public spouse: PersonDTO | null = null;

    public address: AddressDTO | null = null;

    public name: string | null = null;

    public tin: string | null = null;

    public type: AccountType | null = null;


}