import PortalFileDTO from "@/dto/files/PortalFileDTO";
import TranslationLabelDTO from "@/dto/TranslationLabelDTO";

export default class PersonalTaxationFileDTO extends PortalFileDTO{

    public questionnaireFormValueId: number | null = null;

    public questionnaireFormLabel = "";

    public translations: Array<TranslationLabelDTO> = [];

    public period = "";

}