





















import {Component, Vue} from 'vue-property-decorator';
import PortalInput from "@/components/common/PortalInput.vue";
import {CDRQ_Owner} from "@/components/request/cdrq/CDQR_DTOs";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import CDRQDocument from "@/components/request/cdrq/CDRQDocument.vue";
import {ifValid} from "@/utils/ComponentUtils";

@Component({
	components: {CDRQDocument, SingleFileHolder, PhoneInput, PortalCheckbox, PortalInput}
})
export default class CDRQOwner extends Vue {
	private owner = new CDRQ_Owner()

	save() {
		ifValid(this, () => {
			this.$emit("save", this.owner)
			this.owner = new CDRQ_Owner()
		})
	}
}
