














import {Vue, Component, Prop} from 'vue-property-decorator';
import {ifValid} from "@/utils/ComponentUtils";

@Component({})
export default class TextFieldModal extends Vue {


  private text = "";

  @Prop({default: 4})
  rows!: number;

  @Prop({default: null})
  onClose!: () => void | null

  @Prop()
  onOk!: (text: string) => void;

  @Prop({default: ''})
  title!: string;

  @Prop({default: false})
  required!: boolean;

  cancel(){
    if(this.onClose){
      this.onClose()
    }
    this.$modal.hideAll();
  }

  ok(){
    ifValid(this, () => {
      this.onOk(this.text);
      this.$modal.hideAll();
    });
  }

}
