




























































import {Vue, Component, VModel} from 'vue-property-decorator';
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import {CDRQ_DTO, CDRQComment, CreateCDRQCommentPayload} from "@/components/request/cdrq/CDQR_DTOs";
import CDRQFileHolder from "@/components/request/cdrq/CDRQFileHolder.vue";
import FilesListModal from "@/components/taxation/individual/FilesListModal.vue";
import CDRQService from "@/services/request/CDRQService";
import Application from "@/state/Application";
import {processError} from "@/utils/ComponentUtils";
import moment from "moment";
import {Moment} from "moment-timezone";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";

@Component({
	components: {SingleFileHolder, FilesListModal, CDRQFileHolder, PortalTextarea},
	methods: {
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		}
	}
})
export default class CDRQComments extends Vue {

	private pagesNum = 1
	private attachFile = false

	private pageSize = 3;

	@VModel({type: CDRQ_DTO})
	private cdqr!: CDRQ_DTO

	private currentComment = new CreateCDRQCommentPayload()

	get commentPage(): Array<CDRQComment> {
		this.cdqr.comments.slice()
		return this.cdqr.comments.slice(-(this.pagesNum * this.pageSize))
	}

	private sendComment() {
		if (!this.currentComment.content && this.currentComment.files.length == 0) return
		this.currentComment.step = this.cdqr.step
		this.currentComment.publicId = this.cdqr.publicId
		Application.startLoading();
		CDRQService.sendComment(this.currentComment).then(
			() => {
				this.cdqr.comments.push(
					new CDRQComment(
						{
							content: this.currentComment.content,
							step: this.currentComment.step,
							sentBy: "Me",
							client: !this.$wss.isUserSublimeEmployee,
							files: this.currentComment.files,
							created: new Date(),
						}
					)
				)
				this.currentComment = new CreateCDRQCommentPayload(
					{
						publicId: this.cdqr.publicId
					}
				)
				this.$emit("input", this.cdqr)
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	toggleAttachment() {
		this.attachFile = !this.attachFile
	}

	nextPage() {
		this.pagesNum = this.pagesNum + 1
	}

	deleteFile(file: FileMetaDTO) {
		this.currentComment.files = this.currentComment.files.filter(it => it.id != file.id)
	}

	changeDoc(doc: FileMetaDTO) {
		this.currentComment.files.push(doc)
	}
}
