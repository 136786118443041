import axios from "axios";
import Form1099NECPreparationDTO from "@/dto/preparation/Form1099NECPreparationDTO";
import {PREPARATION_1099NEC_ENDPOINT, PREPARATION_PUBLIC_1099NEC_ENDPOINT} from "@/constants/endpoints";
import AddForm1099NECPreparationPayload from "@/dto/preparation/AddForm1099NECPreparationPayload";
import authHeader from "@/services/auth-header";
import SavePublicForm1099NECPreparationPayload from "@/dto/preparation/SavePublicForm1099NECPreparationPayload";
import EditForm1099NECPreparationPayload from "@/dto/preparation/EditForm1099NECPreparationPayload";
import Page from "@/dto/taxation/Page";
import QueryUtils from "@/utils/QueryUtils";

class PreparationService {

    get1099NECPreparations(pageNumber: number, pageSize: number, payerId?: number) {
        const queryString = QueryUtils.buildQueryString(
            ["page", pageNumber],
            ["pageSize", pageSize],
            ["payerId", payerId],
        )
        return axios.get<Page<Form1099NECPreparationDTO>>(`${PREPARATION_1099NEC_ENDPOINT}${queryString}`, {headers: authHeader()});
    }

    prepareNew1099NEC(payload: AddForm1099NECPreparationPayload) {
        return axios.post(`${PREPARATION_1099NEC_ENDPOINT}`, payload, {headers: authHeader()});
    }

    getPublicForm1099NECPreparation(id: string) {
        return axios.get(`${PREPARATION_PUBLIC_1099NEC_ENDPOINT}/${id}`);
    }

    savePublic1099NECPreparation(id: string, payload: SavePublicForm1099NECPreparationPayload) {
        return axios.put(`${PREPARATION_PUBLIC_1099NEC_ENDPOINT}/${id}`, payload);
    }

    save1099NECPreparation(id: number, payload: EditForm1099NECPreparationPayload) {
        return axios.put(`${PREPARATION_1099NEC_ENDPOINT}/${id}`, payload, {headers: authHeader()});
    }

}

export default new PreparationService();