






















import {Vue, Component, VModel} from 'vue-property-decorator';
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import CDRQRecordStatus from "@/components/request/cdrq/CDRQRecordStatus.vue";
import {CDRQFileMetaList, CDRQRecordStatuses} from "@/components/request/cdrq/CDQR_DTOs";
import Notifications from "@/state/Notifications";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {Md5} from "ts-md5";
import CDRQFileHolder from "@/components/request/cdrq/CDRQFileHolder.vue";

@Component({
	computed: {
		CDRQRecordStatuses() {
			return CDRQRecordStatuses
		}
	},
	methods: {
		hashCode(obj: any): string {
			return Md5.hashStr(JSON.stringify(obj))
		},
	},
	components: {CDRQFileHolder, CDRQRecordStatus, SingleFileHolder}
})
export default class CDRQDocumentList extends Vue {

	@VModel({type: CDRQFileMetaList, required: true})
	private cdrqFileMetaList!: CDRQFileMetaList;

	private onChange(doc: FileMetaDTO | null) {
		if (doc != null) {
			this.cdrqFileMetaList.docs.push(doc);
			this.$emit("input", this.cdrqFileMetaList);
		}
	}

	public created(): void {
		this.$validator.extend(
			"requiredOkCdrqFileList",
			{
				validate: value => {
					if (value.docs.length == 0 && value.status == CDRQRecordStatuses.OK) {
						Notifications.error(value.title + " file was not uploaded!")
						return false
					} else {
						return true
					}
				}
			}
		)
	}

	private onDelete(doc: FileMetaDTO) {
		this.cdrqFileMetaList.docs = this.cdrqFileMetaList.docs.filter(it => it.id != doc.id)
		this.$emit("input", this.cdrqFileMetaList)
	}
}
