















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { nodeMap, RouterTreeNode } from "@/router/RouterTree";
import Workspaces from "@/state/Workspaces";
import UserDTO from "@/dto/UserDTO";
import { namespace } from "vuex-class";
import Auth from "@/state/Auth";
import InsuranceDTO from "@/dto/archive/insurance/InsuranceDTO";
import { InsuranceType } from "@/dto/archive/insurance/InsuranceType";
import DateUtils from "@/utils/DateUtils";
import InsuranceSettingsDTO from "@/dto/archive/insurance/InsuranceSettingsDTO";

const Auth_ = namespace("Auth");
const AppModule = namespace("App");
const Insurance = namespace("Insurance");

@Component<Viewer>({
	computed: {
		nodeMap() {
			return nodeMap
		}
	},
  watch: {
    insuranceSettings() {
      this.hideShowInsuranceLink();
    }
  }
})
export default class Viewer extends Vue {

  @AppModule.State
  private isMobile!: boolean;

  @Auth_.State("user")
  private currentUser!: UserDTO;

  @Auth_.Getter("hasAdministrativePrivileges")
  private hasAdministrativePrivileges!: boolean;

  @Auth_.Action
  private signOut!: () => void;

  @Auth_.Getter
  private isLoggedIn!: boolean;

  @Insurance.State("settings")
  private insuranceSettings!: InsuranceSettingsDTO;

	private links: Array<RouterTreeNode> = [];

	private nodeName: string | null = "";

  @Prop({ default: false })
  private hasViewerInside!: false;

  @Prop({ default: null })
  private latestInsurances!: Array<InsuranceDTO> | null;

	public mounted(): void {
    this.setLinksAndNodeName();
	}

	@Watch('$route')
	public onRouteChanged(): void {
		this.setLinksAndNodeName();
	}

  expirationDateLatestInsurance(typeInsurance: InsuranceType): string {
    if (typeInsurance) {
      const endDate = this.latestInsurances?.find(insurance => insurance.type === typeInsurance)?.endDate;
      return endDate ? DateUtils.formatDateToAmericanFormat(endDate) : 'Missing';
    }
    return '';
  }

  setLinksAndNodeName(): void {
    const newElement = nodeMap.get(this.$route.name || null);
    const node = (newElement?.children.length == 0 ? nodeMap.get(newElement.parentName) : newElement) as RouterTreeNode;
    this.links = node.children.filter(node => this.isAvailable(node) && !node.isHidden)
    if (!this.hasViewerInside || (this.hasViewerInside && this.$route.name !== this.$routeNames.ARCHIVE_INSURANCE)) {
      this.nodeName = node.name
    }
  }

  isSettingEnabled(insuranceType: InsuranceType | null): boolean {
    if (this.insuranceSettings.generalLiabilityRequired === false && insuranceType === InsuranceType.GENERAL_LIABILITY) {
      return true;
    }
    if (this.insuranceSettings.professionalLiabilityRequired === false && insuranceType === InsuranceType.PROFESSIONAL_LIABILITY) {
      return true;
    }
    if (this.insuranceSettings.workersCompensationRequired === false && insuranceType === InsuranceType.WORKERS_COMPENSATION) {
      return true;
    }
    if (this.insuranceSettings.disabilityRequired === false && insuranceType === InsuranceType.DISABILITY) {
      return true;
    }
    return false;
  }

	isAvailable(node: RouterTreeNode): boolean {
		if(this.isStateless(node) && node.role && !Auth.hasPermission(node.role)){
			return false;
		}
		if(node.isLoggedIn && !this.isLoggedIn){
			return false;
		}
		if(node.isLoggedOut && this.isLoggedIn){
			return false;
		}

		if(node.isAdmin && !this.hasAdministrativePrivileges){
      return false;

    }
    if (node.isHiddenForAdmin && this.hasAdministrativePrivileges) {
      return false;
    }
    if(!node.availableForAdminOnSidePanelByDefault
			&& this.hasAdministrativePrivileges
			&& Workspaces.getCurrent?.userId == this.currentUser.id
			&& this.$route.name != node.name
			&& !node.hasChildren(this.$route.name as string)){
			return false;
		}
		if (!node.availableForEveryoneOnSidePanelByDefault
			&& this.$route.name != node.name
			&& !node.hasChildren(this.$route.name as string)) {
			return false;
		}
		if (!this.isStateless(node) && !node.availableForCompany && Workspaces.isCurrentWorkspaceCompany) {
			return false;
		}
		if (!this.isStateless(node) && !node.availableForPerson && Workspaces.isCurrentWorkspacePerson) {
			return false;
		}
		if(!this.isStateless(node) && !node.availableForEmployee && Workspaces.isCurrentWorkspaceSublimeEmployee){
			return false;
		}
		if(node.role && Workspaces.getCurrent?.userId != this.currentUser.id && !Auth.hasPermission(node.role)){
			return false;
		}
		return true;
	}

	isStateless(node: RouterTreeNode): boolean {
		return !(node.availableForCompany || node.availableForPerson || node.availableForEmployee);
	}

  hideShowInsuranceLink(): void {
    const newElement = nodeMap.get(this.$route.name || null);
    const node = (newElement?.children.length == 0 ? nodeMap.get(newElement.parentName) : newElement) as RouterTreeNode;
    node.children.forEach((link) => {
      link.isHidden = this.isSettingEnabled(link.insuranceType);
    });
  }
}
