import IAARAddressDTO from "@/dto/request/iaar/IAARAddressDTO";
import { IAARPersonStatus } from "@/constants/request/IAARConstants";

export default class IAARPersonDTO {

    id!: number;
    correctedAddress: null | IAARAddressDTO = null;
    currentAddress: null | IAARAddressDTO = null;
    personEmail!: string;
    personName!: string;
    currentIsValid!: null;
    status!: IAARPersonStatus;


}
