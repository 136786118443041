










import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import {CDRQStep} from "@/components/request/cdrq/CDQR_DTOs";

@Component({
	computed: {
		CDRQStep() {
			return CDRQStep
		}
	}
})
export default class CDRQCard extends Vue {
	@Prop()
	private step!: CDRQStep

	@VModel({type: String})
	private cdrqStep!: CDRQStep

  @Prop({default: true})
  private showNext!: boolean;

  @Prop({default: true})
  private showBack!: boolean;
}
