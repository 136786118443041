











































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import Workspace from "@/dto/auth/Workspace";
import Workspaces from "@/state/Workspaces";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import StaffRegistrationModal from "@/components/payroll/business/employments/employee/EmployeeRegistrationModal.vue";
import RouteNames from "@/router/RouteNames";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import EmployeeService from "@/services/EmployeeService";
import {EmployeeFilter} from "@/dto/payroll/Filters";
import PortalInput from "@/components/common/PortalInput.vue";
import {ifValid, processError} from "@/utils/ComponentUtils";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import Application from "@/state/Application";
import CreateRER from "@/components/request/rer/CreateRER.vue";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import SelectOption from "@/components/common/SelectOption";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";

const AppModule = namespace("App");

@Component({
	components: {PortalRadio, PortalCollapse, CreateRER, PaginationComponent, PortalInput},
	methods: {
		RouteNames() {
			return RouteNames
		}
	}
})
export default class Employees extends Vue {

	private workspace: Workspace = Workspaces.getCurrent;

	private employees: Array<EmployeeDto> = [];

	@AppModule.State
	private isMobile!: boolean;

	private successful = true;

	private message = "";

	@Prop({default: null})
	private action!: (arg: any) => void | null;

	private filter = new EmployeeFilter()

	private totalPages = 1;

	private createRequestMode = false;


	public mounted(): void {
		this.filter.employerId = this.workspace.id;
		this.loadEmployees();
	}

	private reload(){
		this.createRequestMode = false;
	}

	private reset() {
		this.filter = new EmployeeFilter(
			{
				employerId: this.workspace.id
			}
		)
		this.loadEmployees()
	}

	private search() {
		ifValid(this, () => this.loadEmployees())
	}
	private loadEmployees(): void {
		Application.startLoading()
		this.filter.employerId = this.workspace.id;
		EmployeeService.findByFilter(this.filter).then(
			res => {
				this.employees = res.data.data;
				this.totalPages = res.data.countOfPages
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	get isClient(): boolean{
		return !Workspaces.isUserSublimeEmployee;
	}

	private addEmployee(): void {
		this.$modal.show(
			StaffRegistrationModal,
			{
				title: "Register employee",
				callback: () => this.loadEmployees()
			},
			{
				styles: {
					scrollable: true
				}
			}
		)
	}

	changePage(page: number){
		this.filter.pageNumber = page - 1;
		this.loadEmployees();
	}

	get statusOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title("All").value(null).build(),
			SelectOption.builder().title("Active employees").value([EmploymentStatus.NEW, EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS]).build(),
			SelectOption.builder().title("Terminated employees").value([EmploymentStatus.TERMINATED]).build()
		]
	}

}

