




























































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import SignableDocumentsDTO from "@/dto/signature/SignableDocumentsDTO";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import SignatureService from "@/services/SignatureService";
import SignableDocumentsFilter from "@/dto/signature/SignableDocumentsFilter";
import { processError } from "@/utils/ComponentUtils";
import PersonSearch from "@/components/common/PersonSearch.vue";
import PersonDTO from "@/dto/person/PersonDTO";
import { DocumentSigningStatus } from "@/constants/SignatureConstants";
import SelectOption from "@/components/common/SelectOption";
import PortalSelect from "@/components/common/PortalSelect.vue";
import DocumentUtils from "@/utils/DocumentUtils";
import RequestCompanySignatureForFilledDocumentModal
  from "@/components/documents/signing/RequestCompanySignatureForFilledDocumentModal.vue";
import SignerDTO from "@/dto/signature/SignerDTO";
import Notifications from "@/state/Notifications";
import SignersDTO from "@/dto/signature/SignersDTO";
import SublimeSearch from "@/components/common/SublimeSearch.vue";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";
import PortalDate from "@/components/common/PortalDate.vue";
import SelectProfileTypeForPrefilledDocumentModal from "@/components/documents/signing/SelectProfileTypeForPrefilledDocumentModal.vue";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component<SignableDocumentsListNew>({
  components: {PortalDate, SublimeSearch, PortalSelect, PersonSearch, PaginationComponent },
  computed: {
    SelectOption() {
      return SelectOption
    },
    DocumentSigningStatus() {
      return DocumentSigningStatus;
    },
    DocumentUtils() {
      return DocumentUtils;
    }
  }
})
export default class SignableDocumentsListNew extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private signer: PersonDTO | null = null;
  private signableDocuments: Array<SignableDocumentsDTO> | [] = [];
  private filter = new SignableDocumentsFilter();
  private totalPages = 1;

  selectedEmployee: SublimeEmployeeDTO | null = null;

  mounted(): void {
    this.loadDocuments();
  }

  loadDocuments(): void {
      this.startLoading();
      SignatureService.getSignableDocuments(this.filter).then(
          response => {
              this.signableDocuments = response.data.content;
              this.totalPages = response.data.totalPages;
              this.stopLoading();
          },
          error => {
              processError(error, this);
              this.stopLoading();
          }
      );
  }

  private selectSigner(signer: PersonDTO) {
    this.filter.signerId = signer.id;
    this.loadDocuments();
  }

  changePage(page: number): void {
    this.filter.pageNumber = page;
    this.loadDocuments();
  }

  clearFilter(): void {
      this.filter = new SignableDocumentsFilter();
      this.loadDocuments();
  }

  sendPrefilled(): void {
    this.$modal.show(
        SelectProfileTypeForPrefilledDocumentModal,
        {
          onCreated: () => {
            this.clearFilter();
            Notifications.info('Document has been sent');
          }
        }
    )
  }

  sendReminder(signer: SignersDTO): void {
    this.startLoading();
    SignatureService.sendReminder(signer.id).then(
        ok => {
          Notifications.info(`Reminder for ${signer.name} has been sent`);
          this.stopLoading();
        },
        err => {
          processError(err, this);
          this.stopLoading();
        }
    )
  }

  onEmployeeChanged(employee: SublimeEmployeeDTO): void {
    this.filter.assigneeId = employee.id;
    this.loadDocuments();
  }

  triggerCallback(documentId: number): void {
    this.startLoading();
    SignatureService.triggerCallback(documentId).then(
        ok => {
          this.stopLoading();
        },
        err => {
          processError(err, this);
          this.stopLoading();
        }
    )
  }

}
