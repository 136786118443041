import CompanyDTO from "@/dto/company/CompanyDTO";
import SelectOption from "@/components/common/SelectOption";
import {RequestMetaDTO} from "@/dto/request/RequestMetaDTO";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import AddressDTO from "@/dto/AddressDTO";
import {getCustomerUUID} from "@/services/auth-header";
import {v4 as uuidv4} from "uuid";
import {CDRQStatus} from "@/constants/request/RequestsStatuses";
import {PayrollReportFilter} from "@/components/payroll/business/employments/employee/PayrollReport";

export enum CDRQStep {
    FORMATION_DOCUMENTS = "FORMATION_DOCUMENTS",
    OWNERSHIP = "OWNERSHIP",
    DETAILS = "DETAILS",
    FINANCIAL = "FINANCIAL",
    TAX = "TAX",
    ACCOUNTING = "ACCOUNTING",
    EMPLOYEE = "EMPLOYEE",
    CONTRACTS = "CONTRACTS",
    LICENCES = "LICENCES",
    INSURANCE = "INSURANCE",
    ADDITIONAL = "ADDITIONAL",
    SUMMARY = "SUMMARY"
}

export enum CDRQRecordStatuses {
    OK = "OK",
    NONE = "NONE",
    LATER = "LATER"
}

export const CDQR_STEP_OPTIONS: Array<SelectOption> = [
    SelectOption.builder().title("Business Formation Documents").value(CDRQStep.FORMATION_DOCUMENTS).build(),
    SelectOption.builder().title("Ownership and Management Information").value(CDRQStep.OWNERSHIP).build(),
    SelectOption.builder().title("Business Details").value(CDRQStep.DETAILS).build(),
    SelectOption.builder().title("Financial Records").value(CDRQStep.FINANCIAL).build(),
    SelectOption.builder().title("Tax Documents").value(CDRQStep.TAX).build(),
    SelectOption.builder().title("Accounting Records").value(CDRQStep.ACCOUNTING).build(),
    SelectOption.builder().title("Employee Information").value(CDRQStep.EMPLOYEE).build(),
    SelectOption.builder().title("Contracts").value(CDRQStep.CONTRACTS).build(),
    SelectOption.builder().title("Licences").value(CDRQStep.LICENCES).build(),
    SelectOption.builder().title("Insurance Documents").value(CDRQStep.INSURANCE).build(),
    SelectOption.builder().title("Additional Documents").value(CDRQStep.ADDITIONAL).build(),
]

export class CreateCDRQPayload {
    company: CompanyDTO | null = null;
    requiredInfo: RequiredInfo = new RequiredInfo()
    requiredSteps: Array<CDRQStep> = Object.values(CDRQStep);
}

export class CDRQFileMeta {
    doc: FileMetaDTO | null = null;
    title!: string;
    status!: CDRQRecordStatuses

    constructor(init?: Partial<CDRQFileMeta>) {
        Object.assign(this, init)
    }
}

export class CDRQFileMetaList {
    docs: Array<FileMetaDTO> = []
    title!: string;
    status!: CDRQRecordStatuses

    constructor(init?: Partial<CDRQFileMeta>) {
        Object.assign(this, init)
    }
}

export class CDRQBusinessDocuments {
    readonly articleOfIncorporation!: CDRQFileMeta
    readonly operationAgreement!: CDRQFileMeta
    readonly bylaws!: CDRQFileMetaList
    readonly ss4!: CDRQFileMeta
    readonly fillingReceipt!: CDRQFileMeta
    readonly certificateOfGoodStanding!: CDRQFileMeta
}

export class CDRQBusinessHeads {
    owners: Array<CDRQ_Owner> = []
    directors: Array<CDRQ_Director> = []

}

export class CDRQ_Owner {
    firstName: string | null = null
    lastName: string | null = null
    ownership: number | null = null
    ssn: string | null = null
    email: string | null = null
    officer = false
    citizenship: string | null = null
    phone: string | null = null
    uuid = uuidv4()
    idFront = new CDRQFileMeta({
            title: "ID front side",
            status: CDRQRecordStatuses.NONE
        })
    idBack = new CDRQFileMeta({
        title: "ID back side",
        status: CDRQRecordStatuses.NONE
    })
    ssnForm = new CDRQFileMeta({
        title: "SSN card",
        status: CDRQRecordStatuses.NONE
    })
}

export class CDRQ_Director {
    firstName: string | null = null
    lastName: string | null = null
    ownership: number | null = null
    ssn: string | null = null
    email: string | null = null
    officer = false
    citizenship: string | null = null
    phone: string | null = null
    uuid = uuidv4()
}

export class CDRQBusinessDetails {
    address = new AddressDTO()
    phone: string | null = null
    email!: string
    activityDescription: string | null = null
    activityStates: Array<string> = []
}

export class CBIRFinancialRecords {
    readonly bankStatements!: CDRQFileMetaList
    readonly financialStatements!: CDRQFileMetaList
    readonly loans!: CDRQFileMetaList
    readonly creditCards!: CDRQFileMetaList
    readonly assets!: CDRQFileMetaList
}

export class CDRQTaxDocuments {
    readonly taxReturns!: CDRQFileMetaList
    readonly w9!: CDRQFileMetaList
    readonly stateTaxPermits!: CDRQFileMetaList
}

export class CDRQAccountingRecords {
    readonly bookkeepingRecords!: CDRQFileMetaList
    readonly accounts!: AccountingSoftwareList
}

export class AccountingSoftwareList {
    readonly softwareAccounts!: Array<AccountingSoftware>
    readonly title!: string
    readonly status!: CDRQRecordStatuses
}

export class CDRQAccountingSoftwareList {
    softwareAccounts!: Array<AccountingSoftware>
    readonly title!: string
    status!: CDRQRecordStatuses
}

export class CDRQEmployeeInfo {
    readonly employees!: CDRQFileMeta
    readonly w4s!: CDRQFileMetaList
    readonly i9s!: CDRQFileMetaList
    readonly payroll!: CDRQFileMetaList
    readonly contractorAgreements!: CDRQFileMetaList
}

export class CDRQContracts {
    readonly keyContracts!: CDRQFileMetaList
}

export class CDRQLicences {
    readonly licence!: CDRQFileMetaList
    readonly permits!: CDRQFileMetaList
}

export class CDRQInsuranceDocs {
    readonly generalLiability!: CDRQFileMeta
    readonly professionalLiability!: CDRQFileMeta
    readonly commercialPropertyLiability!: CDRQFileMeta
    readonly commercialAuto!: CDRQFileMeta
    readonly cyberLiability!: CDRQFileMeta
    readonly commercialUmbrella!: CDRQFileMeta
    readonly directorsAndOfficers!: CDRQFileMeta
    readonly workersCompensations!: CDRQFileMeta
    readonly disabilityInsurance!: CDRQFileMeta
    readonly healthInsurance!: CDRQFileMeta
    readonly lifeInsurance!: CDRQFileMeta
}

export class CDRQAdditionalDocuments {
    readonly investorAgreements!: CDRQFileMetaList
    readonly legalClaims!: CDRQFileMetaList
}

export class AccountingSoftware {
    url: string | null = null
    title: string | null = null
    login: string | null = null
    password: string | null = null
    uuid: string = uuidv4()
}

export class CDRQ_DTO {
    readonly id!: number;
    readonly publicId!: string;
    readonly requestMeta!: RequestMetaDTO;
    readonly requiredInfo!: RequiredInfo
    readonly requiredSteps!: Array<CDRQStep>;
    status!: CDRQStatus;
    step!: CDRQStep;
    readonly businessDocs!: CDRQBusinessDocuments
    readonly management!: CDRQBusinessHeads
    readonly details!: CDRQBusinessDetails
    readonly financialRecords!: CBIRFinancialRecords
    readonly tax!: CDRQTaxDocuments
    readonly accountingRecords!: CDRQAccountingRecords
    readonly employeeInfo!: CDRQEmployeeInfo
    readonly contracts!: CDRQContracts
    readonly licences!: CDRQLicences
    readonly insuranceDocs!: CDRQInsuranceDocs
    readonly additionalDocs!: CDRQAdditionalDocuments
    confirmation: ConfirmationDocument | null = null
    readonly comments: Array<CDRQComment> = []
    readonly hasLaterRecordStatus!: boolean
}

export class RequiredInfo {
    articleOfIncorporation = true
    operationAgreement = true
    bylaws = true
    ss4 = true
    fillingReceipt = true
    certificateOfGoodStanding = true
    owners = true
    directors = true
    address = true
    phone = true
    email = true
    activityDescription = true
    activityStates = true
    bankStatements = true
    financialStatements = true
    loans = true
    creditCards = true
    assets = true
    taxReturns = true
    w9 = true
    stateTaxPermits = true
    accounts = true
    bookkeepingRecords = true
    employees = true
    w4s = true
    i9s = true
    payroll = true
    contractorAgreements = true
    keyContracts = true
    licences = true
    permits = true
    investorAgreements = true
    legalClaims = true
    generalLiability = true
    professionalLiability = true
    commercialPropertyLiability = true
    commercialAuto = true
    cyberLiability = true
    commercialUmbrella = true
    directorsAndOfficers = true
    workersCompensations = true
    disabilityInsurance = true
    healthInsurance = true
    lifeInsurance = true
}

export class ConfirmationDocument {
    readonly doc!: FileMetaDTO
    readonly status!: string
    readonly signerPublicId?: string
}

export class CDRQComment {
    content: string | null = null
    step: CDRQStep | null = null
    sentBy: string | null = null
    client: boolean | null = null
    files: Array<FileMetaDTO> = []
    created: Date | null = null

    constructor(init?: Partial<CDRQComment>) {
        Object.assign(this, init)
    }
}

export class CreateCDRQCommentPayload {
    publicId: string | null = null
    step: CDRQStep | null = null
    content: string | null = null
    files: Array<FileMetaDTO> = []
    sentBy= "Me"

    constructor(init?: Partial<CreateCDRQCommentPayload>) {
        Object.assign(this, init)
    }
}

export class MetaCDRQ {
    readonly step!: CDRQStep
    confirmation: ConfirmationDocument | null = null
    readonly status!: CDRQStatus
}