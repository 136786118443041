










































































import {Component, Prop, Vue} from 'vue-property-decorator';
import BankAccountDTO from "@/dto/BankAccountDTO";
import PersonService from "@/services/PersonService";
import Workspace, {WorkspaceType} from "@/dto/auth/Workspace";
import Workspaces from "@/state/Workspaces";
import CompanyService from "@/services/CompanyService";
import {BankAccountType} from "@/dto/BankAccountType";
import {HolderType} from "@/dto/HolderType";
import {STATE_LIST} from "@/constants/ProfileConstants";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import {ifValid, processError} from "@/utils/ComponentUtils";
import PortalInput from "@/components/common/PortalInput.vue";
import Application from "@/state/Application";
import Notifications from "@/state/Notifications";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import {OptionsBuilder} from "@/components/common/SelectOption";
import {AccountType} from "@/constants/AccountTypes";

@Component({
	computed: {
		BankAccountType() {
			return BankAccountType
		},
		AccountType() {
			return AccountType
		},
		HolderType() {
			return HolderType
		},
		OptionsBuilder() {
			return OptionsBuilder
		},
		AutoCompleteDTO() {
			return AutoCompleteDTO
		}
	},
	components: {PortalRadio, PortalInput, AutoComplete}
})
export default class CreationBankAccountListModal extends Vue {

	private bankAccount: BankAccountDTO = new BankAccountDTO();

	private states: Array<string> = STATE_LIST;

	private workspace: Workspace = Workspaces.getCurrent as Workspace;

	@Prop()
	private onSuccess!: () => void

	public created(): void {
        this.bankAccount.accountHolder = this.workspace.name;
		this.bankAccount.holderType = this.$wss.getCurrentType == WorkspaceType.COMPANY ? HolderType.BUSINESS : HolderType.INDIVIDUAL;
	}

	private accountNumber(str: string) {
		if (str.length >= 9 && this.bankAccount.accountNumber !== null) {
			this.bankAccount.accountNumber = this.bankAccount.accountNumber.trim().substr(0, 9);
		}
	}

	private routingNumber(str: string) {
		if (str.length >= 9 && this.bankAccount.routingNumber !== null) {
			this.bankAccount.routingNumber = this.bankAccount.routingNumber.trim().substr(0, 9);
		}
	}

	private closeModal(){
		this.$modal.hideAll();
	}

	private save(): void {
		ifValid(this, () => {
			Application.startLoading()
			if (this.bankAccount.state != null) {
				this.bankAccount.state = this.bankAccount.state.toUpperCase();
			}
			switch (this.workspace.type) {
				case WorkspaceType.COMPANY: {
					this.saveCompanyBankAccount()
					break;
				}
				case WorkspaceType.PERSON:{
					this.savePersonBankAccount()
					break;
				}
			}
		})
	}

	private processResp(): void {
		Application.stopLoading();
		this.$modal.hideAll();
		Notifications.success("The bank account has been successfully saved!");
		this.onSuccess();
	}

	private saveCompanyBankAccount() {
		this.bankAccount.companyId = this.workspace.id;
		CompanyService.addBankAccount(this.bankAccount).then(
			() => this.processResp(),
			err => processError(err, this)
		)
	}

	private savePersonBankAccount() {
		this.bankAccount.personId = this.workspace.id
		PersonService.addBankAccount(this.bankAccount).then(
			() => this.processResp(),
			err => processError(err, this)
		)
	}

}
