




















































import {Component, Vue} from "vue-property-decorator";
import InvitationCompletionRequestDTO from "@/dto/auth/InvitationCompletionRequestDTO";
import {namespace} from "vuex-class";
import RouteNames from "@/router/RouteNames";
import PortalInput from "@/components/common/PortalInput.vue";
import {ifValid, processError} from "@/utils/ComponentUtils";
import Notifications from "@/state/Notifications";
import StepPasswordCheck from "@/components/common/StepPasswordCheck.vue";

const Auth = namespace("Auth");

@Component({
	components: {StepPasswordCheck, PortalInput}
})
export default class InvitationCompletion extends Vue {

  private successful = false;

  private message = "";

  private loading = false;

  private request = new InvitationCompletionRequestDTO(this.$route.params.invitationCode);

  @Auth.Action
  private completeInvitation!: (request: InvitationCompletionRequestDTO) => Promise<any>;

  handleRegister() {
    this.message = "";
    ifValid(this, () => {
      this.loading = true;
      this.completeInvitation(this.request).then(
        (data) => {
          this.loading = false;
          Notifications.success("Your account registered successfully");
          this.successful = true;
          this.$router.push({name: RouteNames.HOME});
        },
        err => processError(err, this)
      );
    })
  }

}
