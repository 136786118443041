



































































































































































































import {Component, Vue} from "vue-property-decorator";
import DocumentService from "@/services/DocumentService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {namespace} from "vuex-class";
import SaveW9RequestDTO, {LLCTaxClassification} from "@/dto/archive/SaveW9RequestDTO";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import {HolderType} from "@/dto/HolderType";
import {BankAccountType} from "@/dto/BankAccountType";
import {ifValid} from "@/utils/ComponentUtils";
import AddressDTO from "@/dto/AddressDTO";
import BankAccountDTO from "@/dto/BankAccountDTO";
import RouteNames from "@/router/RouteNames";
import { FillableDocumentStatus } from "@/dto/archive/FillableDocumentStatus";
import {FederalTaxClassification} from "@/constants/FederalTaxClassification";
import PortalTextarea from "@/components/common/PortalTextarea.vue";

const AppModule = namespace("App");

@Component({
	computed: {
		AutoCompleteDTO() {
			return AutoCompleteDTO
		},
		SelectOption() {
			return SelectOption
		}
	},
	components: {PortalTextarea, AutoComplete, PortalSelect, PortalInput}
})
export default class FormW9Person extends Vue {

	private successful = false;

	private message = "";

	@AppModule.State
	private isMobile!: boolean;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private request: SaveW9RequestDTO = new SaveW9RequestDTO();

	private exists = true;

	private processed = false;

	private buildPayeeCodeOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title("1 - An organization exempt from tax under section 501(a), any IRA, or a custodial account under section 403(b)(7) if the account satisfies the requirements of section 401(f)(2)").value(1).build(),
			SelectOption.builder().title("2 - The United States or any of its agencies or instrumentalities").value(2).build(),
			SelectOption.builder().title("3 - A state, the District of Columbia, a U.S. commonwealth or possession, or any of their political subdivisions or instrumentalities").value(3).build(),
			SelectOption.builder().title("4 - A foreign government or any of its political subdivisions, agencies, or instrumentalities").value(4).build(),
			SelectOption.builder().title("5 - A corporation").value(5).build(),
			SelectOption.builder().title("6 - A dealer in securities or commodities required to register in the United States, the District of Columbia, or a U.S. commonwealth or possession").value(6).build(),
			SelectOption.builder().title("7 - A futures commission merchant registered with the Commodity Futures Trading Commission").value(7).build(),
			SelectOption.builder().title("8 - A real estate investment trust").value(8).build(),
			SelectOption.builder().title("9 - An entity registered at all times during the tax year under the Investment Company Act of 1940").value(9).build(),
			SelectOption.builder().title("10 - A common trust fund operated by a bank under section 584(a)").value(10).build(),
			SelectOption.builder().title("11 - A financial institution").value(11).build(),
			SelectOption.builder().title("12 - A middleman known in the investment community as a nominee or custodian").value(12).build()
		];
	}

	private buildFATCACodeOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title("A - An organization exempt from tax under section 501(a) or any individual retirement plan as defined in section 7701(a)(37)").value("A").build(),
			SelectOption.builder().title("B - The United States or any of its agencies or instrumentalities").value("B").build(),
			SelectOption.builder().title("C - A state, the District of Columbia, a U.S. commonwealth or possession, or any of their political subdivisions or instrumentalities").value("C").build(),
			SelectOption.builder().title("D - A corporation the stock of which is regularly traded on one or more established securities markets, as described in Regulations section 1.1472-1(c)(1)(i)").value("D").build(),
			SelectOption.builder().title("E - A corporation that is a member of the same expanded affiliated group as a corporation described in Regulations section 1.1472-1(c)(1)(i)").value("E").build(),
			SelectOption.builder().title("F - A dealer in securities, commodities, or derivative financial instruments (including notional principal contracts, futures, forwards, and options) that is registered as such under the laws of the United States or any state").value("F").build(),
			SelectOption.builder().title("G - A real estate investment trust").value("G").build(),
			SelectOption.builder().title("H - A regulated investment company as defined in section 851 or an entity registered at all times during the tax year under the Investment Company Act of 1940").value("H").build(),
			SelectOption.builder().title("I - A common trust fund as defined in section 584(a)").value("I").build(),
			SelectOption.builder().title("J - A bank as defined in section 581").value("J").build(),
			SelectOption.builder().title("K - A broker").value("K").build(),
			SelectOption.builder().title("L - A trust exempt from tax under section 664 or described in section 4947(a)(1)").value("L").build(),
			SelectOption.builder().title("M - A tax exempt trust under a section 403(b) plan or section 457(g) plan").value("M").build()
		];
	}

  public mounted(): void {
    this.request.classification = FederalTaxClassification.SOLE;
	  this.request.bankAccount.holderType = HolderType.INDIVIDUAL
    if(this.$route.name == RouteNames.PUBLIC_FORMS_W9_PERSON) {
      this.request.publicId = this.$route.params.publicId;
      this.loadPublicForm();
    } else{
      this.request.id = Number.parseInt(this.$route.params.id);
      this.loadInternalForm();
    }
  }

	private loadPublicForm(): void {
		this.startLoading();
		DocumentService.loadPreparedPublicFormW9(this.$route.params.publicId).then(
			success => {
				if (!this.request.address) {
					this.request.address = new AddressDTO();
				}
        if(success.data.bankAccount){
          this.request.bankAccount = success.data.bankAccount
        }else if (!this.request.bankAccount) {
					this.request.bankAccount = new BankAccountDTO();
					this.request.bankAccount.holderType = HolderType.INDIVIDUAL;
					this.request.bankAccount.accountType = BankAccountType.CHECKING;
				}
        if (success.data.status === FillableDocumentStatus.PENDING_SIGNATURE) {
          this.goToSignature(success.data.signerPublicId as string)
        } else {
          this.request.merge(success.data);
        }
				this.stopLoading();
			},
			error => {
				this.exists = false;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string
				this.successful = false;
				this.stopLoading()
			});
	}

  private loadInternalForm(): void {
    this.startLoading();
    DocumentService.loadPreparedFormW9(Number.parseInt(this.$route.params.id)).then(
      success => {
        if (!this.request.address) {
          this.request.address = new AddressDTO();
        }
        if (!this.request.bankAccount) {
          this.request.bankAccount = new BankAccountDTO();
          this.request.bankAccount.holderType = HolderType.INDIVIDUAL;
          this.request.bankAccount.accountType = BankAccountType.CHECKING;
        }
        this.request.merge(success.data);
        this.stopLoading();
      },
      error => {
        this.exists = false;
        this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        this.successful = false;
        this.stopLoading();
      });
  }

  private save(): void {
    if(this.$route.name === RouteNames.PUBLIC_FORMS_W9_PERSON) {
      this.request.publicId = this.$route.params.publicId;
      this.savePublicForm();
    } else {
      this.request.id = Number.parseInt(this.$route.params.id);
      this.saveInternalForm();
    }
  }

	private saveInternalForm(): void {
		ifValid(this, () => {
			this.message = "";
			this.startLoading();
			DocumentService.saveW9(this.request).then(
				success => {
					this.successful = true;
					this.processed = true;
          this.$router.push({name: RouteNames.ARCHIVE_W9});
				},
				error => {
					this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
				}
			).then(() => this.stopLoading());
		});
	}

  private savePublicForm(): void {
    ifValid(this, () => {
      this.message = "";
      this.startLoading();
      DocumentService.savePublicW9(this.request).then(
        success => {
          this.successful = true;
          this.processed = true;
          this.goToSignature(success.data);
        },
        error => {
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        }
      ).then(() => this.stopLoading());
    });
  }

  private goToSignature(publicId: string): void {
    this.$router.push({name: RouteNames.SIGN_PUBLIC, params: { publicId }});
  }

  get isLLC(): boolean{
    return this.request.classification == FederalTaxClassification.LLC;
  }

  get isOther(): boolean{
    return this.request.classification == FederalTaxClassification.OTHER;
  }

  private buildClassificationOptions(): Array<SelectOption> {
    return [
      SelectOption.builder().title("Individual/Sole proprietor").value(FederalTaxClassification.SOLE).build(),
      SelectOption.builder().title("C Corporation").value(FederalTaxClassification.C_CORP).build(),
      SelectOption.builder().title("S Corporation").value(FederalTaxClassification.S_CORP).build(),
      SelectOption.builder().title("Partnership").value(FederalTaxClassification.PARTNERSHIP).build(),
      SelectOption.builder().title("Trust/estate").value(FederalTaxClassification.TRUST_ESTATE).build(),
      SelectOption.builder().title("Limited liability company").value(FederalTaxClassification.LLC).build(),
      SelectOption.builder().title("Other").value(FederalTaxClassification.OTHER).build()
    ];
  }

  private buildLLCClassificationOptions(): Array<SelectOption> {
    return [
      SelectOption.builder().title("C Corporation").value(LLCTaxClassification.C).build(),
      SelectOption.builder().title("S Corporation").value(LLCTaxClassification.S).build(),
      SelectOption.builder().title("Partnership").value(LLCTaxClassification.P).build()
    ];
  }

}
