import {CounterpartyType} from "@/constants/CounterpartyType";

export default class AddForm1099NECPreparationPayload {
    contractorId?: number;
    contractorType?: CounterpartyType;
    year = 2024;
    corrected = false;
    amount?: number;
    resale?: boolean;
    federalWithheld?: number;
    stateWithheld1?: number;
    stateWithheld2?: number;
    payerStateNo1?: string;
    payerStateNo2?: string;
    stateIncome1?: number;
    stateIncome2?: number;
}